import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchNarrative } from './narrativesAPI';

export const fetchNarrativeById = createAsyncThunk(
    'narratives/fetchNarrativeById',
    async (id, { getState }) => {
        const state = getState();
        const existingNarrative = state.narratives.byId[id];
        if (existingNarrative) {
            return existingNarrative; // If narrative is already present, no need to re-fetch
        }
        const response = await fetchNarrative(id);
        return response.data;
    }
);

const narrativesSlice = createSlice({
    name: 'narratives',
    initialState: {
        byId: {
            "1": {
                id: "1",
                name: 'Use of consumer data without consent',
                exposureLevel: 'trending',
                mainPlatforms: 'Twitter, Youtube',
                origin: 'Unknown',
                tag: 'orange',
                summary: 'A disinformation campaign alleging that TechSecure is misusing consumer data without consent. The campaign focuses on privacy concerns and portrays the company as unethical in handling personal information. The campaign appears to be driven by a mix of disgruntled users and possibly rival entities in the industry.',
                keywords: [{ word: 'privacy breach', count: 25 }, { word: 'data misuse', count: 15 }, { word: 'unethical', count: 10 }, { word: 'TechSecure', count: 5 }, { word: 'personal information', count: 16 }, { word: 'disinformation', count: 15 }, { word: 'unauthorized', count: 10 }, { word: 'data breach', count: 11 }],
                platformBreakdown: [{ name: 'Twitter', count: 52, color: "#3547EE" }, { name: 'Youtube', count: 16, color: "#A3ABEF" }, { name: 'Facebook', count: 32, color: "#717BD4" }],
                targetAudience: [{ name: 'Tech-Savy Customers', count: 20, color: "#B8EF8C" }, { name: 'Privacy Advocates', count: 16, color: "#97DC60" }, { name: 'Young Professionals', count: 64, color: "#74C635" }],
                sentimentAnalysis: [{ name: 'Positive', count: 13, color: "#73B342" }, { name: 'Neutral', count: 57, color: "#E5E5E5" }, { name: 'Negative', count: 29, color: "#FF8A79" }],
                demographics: [{ name: 'Age 25-35', count: 65, color: "#4F655C" }, { name: 'Age 36-45', count: 25, color: "#667C73" }, { name: 'Age 46-65', count: 10, color: "#7D938A" }],
            },
            "2": {
                id: "2",
                name: 'Bad Product Reliability',
                exposureLevel: 'filter_bubble',
                mainPlatforms: 'Twitter',
                origin: 'Unknown',
                tag: 'blue',
                summary: 'A disinformation campaign alleging that TechSecure is misusing consumer data without consent. The campaign focuses on privacy concerns and portrays the company as unethical in handling personal information. The campaign appears to be driven by a mix of disgruntled users and possibly rival entities in the industry.',
                keywords: [{ word: 'privacy breach', count: 25 }, { word: 'data misuse', count: 15 }, { word: 'unethical', count: 10 }, { word: 'TechSecure', count: 5 }, { word: 'personal information', count: 16 }, { word: 'disinformation', count: 15 }, { word: 'unauthorized', count: 10 }, { word: 'data breach', count: 11 }],
                platformBreakdown: [{ platform: 'Twitter', count: 52 }, { platform: 'Youtube', count: 16 }, { platform: 'Facebook', count: 32 }],
                targetAudience: [{ group: 'Tech-Savy Customers', count: 20 }, { group: 'Privacy Advocates', count: 16 }, { group: 'Young Professionals', count: 64 }],
                sentimentAnalysis: [{ sentiment: 'Positive', count: 12 }, { sentiment: 'Neutral', count: 57 }, { sentiment: 'Negative', count: 51 }],
                demographics: [{ group: 'Age 25-35', count: 65 }, { group: 'Age 36-45', count: 25 }, { group: 'Age 46-65', count: 10 }]
            },
            "3": {
                id: "3",
                name: 'Incoming Financial Report ',
                exposureLevel: 'little_interest',
                mainPlatforms: 'LinkedIn',
                origin: 'Unknown',
                tag: "red",
                summary: 'A disinformation campaign alleging that TechSecure is misusing consumer data without consent. The campaign focuses on privacy concerns and portrays the company as unethical in handling personal information. The campaign appears to be driven by a mix of disgruntled users and possibly rival entities in the industry.',
                keywords: [{ word: 'privacy breach', count: 25 }, { word: 'data misuse', count: 15 }, { word: 'unethical', count: 10 }, { word: 'TechSecure', count: 5 }, { word: 'personal information', count: 16 }, { word: 'disinformation', count: 15 }, { word: 'unauthorized', count: 10 }, { word: 'data breach', count: 11 }],
                platformBreakdown: [{ platform: 'Twitter', count: 52 }, { platform: 'Youtube', count: 16 }, { platform: 'Facebook', count: 32 }],
                targetAudience: [{ group: 'Tech-Savy Customers', count: 20 }, { group: 'Privacy Advocates', count: 16 }, { group: 'Young Professionals', count: 64 }],
                sentimentAnalysis: [{ sentiment: 'Positive', count: 18 }, { sentiment: 'Neutral', count: 57 }, { sentiment: 'Negative', count: 36 }],
                demographics: [{ group: 'Age 25-35', count: 65 }, { group: 'Age 36-45', count: 25 }, { group: 'Age 46-65', count: 10 }]
            },
        },
        byRegion: {
            byId: {
                "1": {
                    id: 1,
                    name: 'Belgium',
                    numberOfMessages: 159,
                    exposureLevel: 'Trending',
                    narratives: [
                        {
                            narrativeId: "1",
                            spreadPercentage: 55
                        },
                        {
                            narrativeId: "2",
                            spreadPercentage: 35
                        },
                        {
                            narrativeId: "3",
                            spreadPercentage: 10
                        }
                    ]
                },
                "2": {
                    id: 2,
                    name: 'Netherlands',
                    numberOfMessages: 36,
                    exposureLevel: 'Filter Bubble',
                    narratives: [
                        {
                            narrativeId: "1",
                            spreadPercentage: 35
                        },
                        {
                            narrativeId: "2",
                            spreadPercentage: 45
                        },
                        {
                            narrativeId: "3",
                            spreadPercentage: 20
                        }
                    ]
                },
                "3": {
                    id: 3,
                    name: 'Switzerland',
                    numberOfMessages: 25,
                    exposureLevel: 'Little Interest',
                    narratives: [
                        {
                            narrativeId: "1",
                            spreadPercentage: 10
                        },
                        {
                            narrativeId: "2",
                            spreadPercentage: 30
                        },
                        {
                            narrativeId: "3",
                            spreadPercentage: 60
                        }
                    ]
                },
                "4": {
                    id: 4,
                    name: 'Poland',
                    numberOfMessages: 478,
                    exposureLevel: 'Trending',
                    narratives: [
                        {
                            narrativeId: "1",
                            spreadPercentage: 70
                        },
                        {
                            narrativeId: "2",
                            spreadPercentage: 20
                        },
                        {
                            narrativeId: "3",
                            spreadPercentage: 10
                        }
                    ]
                },
                "5": {
                    id: 5,
                    name: 'Czech Republic',
                    numberOfMessages: 12,
                    exposureLevel: 'Filter Bubble',
                    narratives: [
                        {
                            narrativeId: "1",
                            spreadPercentage: 30
                        },
                        {
                            narrativeId: "2",
                            spreadPercentage: 40
                        },
                        {
                            narrativeId: "3",
                            spreadPercentage: 30
                        }
                    ]
                },
                "6": {
                    id: 6,
                    name: 'Slovakia',
                    numberOfMessages: 5,
                    exposureLevel: 'Little Interest',
                    narratives: [
                        {
                            narrativeId: "1",
                            spreadPercentage: 10
                        },
                        {
                            narrativeId: "2",
                            spreadPercentage: 20
                        },
                        {
                            narrativeId: "3",
                            spreadPercentage: 70
                        }
                    ]
                },
                "7": {
                    id: 7,
                    name: 'Hungary',
                    numberOfMessages: 94,
                    exposureLevel: 'Trending',
                    narratives: [
                        {
                            narrativeId: "1",
                            spreadPercentage: 45
                        },
                        {
                            narrativeId: "2",
                            spreadPercentage: 20
                        },
                        {
                            narrativeId: "3",
                            spreadPercentage: 35
                        }
                    ]
                },
                "8": {
                    id: 9,
                    name: 'Croatia',
                    numberOfMessages: 216,
                    exposureLevel: 'Trending',
                    narratives: [
                        {
                            narrativeId: "1",
                            spreadPercentage: 85
                        },
                        {
                            narrativeId: "2",
                            spreadPercentage: 10
                        },
                        {
                            narrativeId: "3",
                            spreadPercentage: 5
                        }
                    ]
                },
            },
            allIds: ["1", "2", "3", "4", "5", "6", "7", "8"],
        },
        messageById: {
            "1": {
                id: "1",
                message: "TechSecure is sharing your personal data without your consent! Protect your privacy.",
                platform: "Twitter",
                interactions: 3000,
                origin: "Unknown",
                source: "https://twitter.com/techsecure",
                sentiment: "Negative",
            },
            "2": {
                id: "2",
                message: "Shocking details on how TechSecure is misusing user data - here's what you need to know.",
                platform: "Facebook",
                interactions: 2450,
                origin: "Unknown",
                source: "https://facebook.com/techsecure",
                sentiment: "Negative",
            },
            "3": {
                id: "3",
                message: "An in-depth look at how TechSecure has breached consumer trust by misusing private information.",
                platform: "Blog",
                interactions: 1506,
                origin: "Unknown",
                source: "https://blog.com/techsecure",
                sentiment: "Negative",
            },
            "4": {
                id: "4",
                message: "TechSecure is sharing your personal data without your consent! Protect your privacy.",
                platform: "Twitter",
                interactions: 3000,
                origin: "Unknown",
                source: "https://twitter.com/techsecure",
                sentiment: "Negative",
            },
            "5": {
                id: "5",
                message: "Shocking details on how TechSecure is misusing user data - here's what you need to know.",
                platform: "Facebook",
                interactions: 2450,
                origin: "Unknown",
                source: "https://facebook.com/techsecure",
                sentiment: "Negative",
            },
            "6": {
                id: "6",
                message: "An in-depth look at how TechSecure has breached consumer trust by misusing private information.",
                platform: "Blog",
                interactions: 1506,
                origin: "Unknown",
                source: "https://blog.com/techsecure",
                sentiment: "Negative",
            },
            "7": {
                id: "7",
                message: "TechSecure is sharing your personal data without your consent! Protect your privacy.",
                platform: "Twitter",
                interactions: 3000,
                origin: "Unknown",
                source: "https://twitter.com/techsecure",
                sentiment: "Negative",
            },
            "8": {
                id: "8",
                message: "Shocking details on how TechSecure is misusing user data - here's what you need to know.",
                platform: "Facebook",
                interactions: 2450,
                origin: "Unknown",
                source: "https://facebook.com/techsecure",
                sentiment: "Negative",
            },
            "9": {
                id: "9",
                message: "An in-depth look at how TechSecure has breached consumer trust by misusing private information.",
                platform: "Blog",
                interactions: 1506,
                origin: "Unknown",
                source: "https://blog.com/techsecure",
                sentiment: "Negative",
            },
        },
        allIds: ["1", "2", "3"],
        selectedNarrativeId: "1",
        mapFilters: { Layers: [] },
        loading: false,
        error: null,
    },
    reducers: {
        selectNarrative: (state, action) => {
            state.selectedNarrativeId = action.payload;
        },
        setMapFilters: (state, action) => {
            console.log(action.payload);
            state.mapFilters = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNarrativeById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNarrativeById.fulfilled, (state, action) => {
                state.loading = false;
                const narrative = action.payload;
                state.byId[narrative.id] = narrative;
                if (!state.allIds.includes(narrative.id)) {
                    state.allIds.push(narrative.id);
                }
            })
            .addCase(fetchNarrativeById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { selectNarrative, setMapFilters } = narrativesSlice.actions;
export default narrativesSlice.reducer;
