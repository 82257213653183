import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Icons
import { HiGlobeAlt, HiOutlineCheckCircle, HiOutlinePencil } from "react-icons/hi2";
import { AiOutlineTwitter, AiFillFacebook, AiFillYoutube } from "react-icons/ai";
import ai_icon from '../../../assets/images/ai_icon.svg';

// Redux
import { useSelector } from 'react-redux';

// Components
import PageHeader from '../../../components/containers/page-header/PageHeader';
import ContentContainer from '../../../components/containers/content-container/ContentContainer';

const AutomaticDataPage = () => {
    const navigate = useNavigate();
    const { selectedProjectId } = useSelector(state => state.projects);

    return (
        <div className="container">
            <PageHeader
                title="Automatic Data Collection" />
            <div className="row g-2">
                <div className="col-12">
                    <ContentContainer title="Data Sources Overview">
                        <p className='text-muted'>
                            You are currently collecting data from <b>4 sources</b>. Below you can see the status of each data source. You can update the data sources by clicking on the buttons below.
                        </p>
                    </ContentContainer>
                </div>
                <div className="col-lg-12 col-xl-6">
                    <ContentContainer title={<><HiGlobeAlt />Webscrapers</>}>
                        <p className='text-muted'>You are currently collecting data from one domain with 24 pages.</p>
                        <h5 className='mt-2'>Domain(s):</h5>
                        <ul className='list-group'>
                            <li class="list-group-item fst-italic">www.techsecure.com</li>
                        </ul>
                        <h5 className='mt-2'>Page(s):</h5>
                        <ul className='list-group'>
                            <li class="list-group-item fst-italic">www.techsecure.com/home</li>
                            <li class="list-group-item fst-italic">www.techsecure.com/blog</li>
                            <li class="list-group-item fst-italic">www.techsecure.com/about</li>
                            <li class="list-group-item">...</li>
                        </ul>
                        <button
                            className="w-100 btn btn-outline-primary mt-3 "
                            onClick={() => navigate(`/projects/${selectedProjectId}/data/automated-collection/webscrapers`)}>
                            Update Webscrapers
                        </button>
                        <div className='d-flex flex-row mt-3 justify-content-between'>
                            <p className='fst-italic m-0'>Last updated: recently</p>
                            <p className='text-end m-0'>
                                Status: <span className='text-success'>active<HiOutlineCheckCircle /></span>
                            </p>
                        </div>
                    </ContentContainer>
                </div>
                <div className="col-lg-12 col-xl-6">
                    <ContentContainer title={<><AiOutlineTwitter />Twitter</>}>
                        <p className='text-muted'>You have attached a Twitter listener that will collect data based on your search queries.</p>
                        <div className='col-12 col-xl-6 border rounded mx-auto mt-auto mb-2 p-3'>
                            <h5>Number of Accounts Found:</h5>
                            <p>34</p>
                        </div>
                        <div className='col-12 col-xl-6 border rounded mx-auto mb-auto p-3'>
                            <h5>Number of Messages:</h5>
                            <p>4905</p>
                        </div>
                        <button
                            style={{ cursor: "not-allowed" }}
                            className="w-100 btn btn-outline-primary mt-3 ">
                            Update Twitter Listener
                        </button>
                        <div className='d-flex flex-row justify-content-between mt-3 '>
                            <p className='fst-italic m-0'>Last updated: recently</p>
                            <p className='text-end m-0'>
                                Status: <span className='text-success'>active<HiOutlineCheckCircle /></span>
                            </p>
                        </div>
                    </ContentContainer>
                </div>
                <div className="col-lg-12 col-xl-6">
                    <ContentContainer title={<><AiFillYoutube />YouTube</>}>
                        <p className='text-muted'>You have attached a YouTube listener that will collect data based on your search queries.</p>
                        <div className='col-12 col-xl-6 border rounded mx-auto mt-auto mb-2 p-3'>
                            <h5>Number of Videos Found:</h5>
                            <p>24</p>
                        </div>
                        <div className='col-12 col-xl-6 border rounded mx-auto mb-auto p-3'>
                            <h5>Number of Comments:</h5>
                            <p>2497</p>
                        </div>
                        <button
                            style={{ cursor: "not-allowed" }}
                            className="w-100 btn btn-outline-primary mt-3 ">
                            Update YouTube Listener
                        </button>
                        <div className='d-flex flex-row justify-content-between mt-3 '>
                            <p className='fst-italic m-0'>Last updated: 2 days ago</p>
                            <p className='text-end m-0'>
                                Status: <span className='text-success'>active<HiOutlineCheckCircle /></span>
                            </p>
                        </div>
                    </ContentContainer>
                </div>
                <div className="col-lg-12 col-xl-6">
                    <ContentContainer title={<><AiFillFacebook />Facebook</>}>
                        <p className='text-muted'>You have attached a Facebook listener that will collect data based on your search queries.</p>
                        <div className='col-12 col-xl-6 border rounded mx-auto mt-auto mb-2 p-3'>
                            <h5>Number of Pages Found:</h5>
                            <p>11</p>
                        </div>
                        <div className='col-12 col-xl-6 border rounded mx-auto mb-auto p-3'>
                            <h5>Number of Posts:</h5>
                            <p>285</p>
                        </div>
                        <button
                            style={{ cursor: "not-allowed" }}
                            className="w-100 btn btn-outline-primary mt-3 ">
                            Update Facebook Listener
                        </button>
                        <div className='d-flex flex-row justify-content-between mt-3 '>
                            <p className='fst-italic m-0'>Last updated: 1 day ago</p>
                            <p className='text-end m-0'>
                                Status: <span className='text-success'>active<HiOutlineCheckCircle /></span>
                            </p>
                        </div>
                    </ContentContainer>
                </div>
            </div>
        </div >
    )
};

export default AutomaticDataPage;