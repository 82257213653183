import React from 'react';
import { NavLink } from 'react-router-dom';
import '../SideBar.css';


const ManualDataLink = ({ NavLinkInfo, last }) => {

    return (
        <NavLink
            to={NavLinkInfo.to}
            className={({ isActive }) =>
                `nav-link py-0 no-highlight ${isActive ? 'text-primary' : ''}`
            }
        >
            <div className='d-flex flex-row align-items-stretch'>
                <div className='align-self-stretch d-flex flex-column me-2 circle-survey' >
                    <div className={`vertical-connector bg-white ${(NavLinkInfo.index === 1) ? "opacity-0" : ""}`} />
                    <div className={`align-content-center rounded-circle bg-light circle-navlink ${(NavLinkInfo.status === 1) && " circle-navlink-completed"}`} style={{ minWidth: "35px", minHeight: "35px" }}>
                        {(NavLinkInfo.status == 1) ?
                            <p className='m-0 text-center tick-navlink'>✓</p>
                            :
                            <p className='m-0 text-center index-navlink'>{NavLinkInfo.index}</p>
                        }
                    </div>
                    <div className={`vertical-connector bg-white ${last ? "opacity-0" : ""}`} />
                </div>
                <div className='align-self-center contained-text py-3'>
                    <p className='mb-1 topic mobile-hide'>{NavLinkInfo.category}</p>
                    <p className={`fs-10 m-0 status mobile-hide ${NavLinkInfo.status == 1 ? "text-success" : "text-danger"}`} style={{ fontSize: "0.8rem" }}>{NavLinkInfo.statusLabel}</p>
                </div>
            </div>
        </NavLink>
    );
};

export default ManualDataLink;
