import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import * as topojson from 'topojson-client';

// styles
import './WorldMap.css';
import { set } from 'firebase/database';


const WorldMap = ({ dynamic = false, question, value, onChange }) => {
    const svgRef = useRef();
    const [selectedCountries, setSelectedCountries] = useState(value);

    // Synchronize selectedCountries state with countries prop
    useEffect(() => {
        setSelectedCountries(value);
    }, [value]);

    const handleCountrySelect = (country) => {
        setSelectedCountries(prevSelectedCountries => {
            const updatedCountries = prevSelectedCountries.includes(country)
                ? prevSelectedCountries.filter(c => c !== country)
                : [...prevSelectedCountries, country];
            return updatedCountries;
        });
    }

    // update the parent component with the selected countries
    useEffect(() => {
        if (!dynamic) return;
        onChange(selectedCountries, question.id);
    }, [selectedCountries]);


    useEffect(() => {
        const width = 960;
        const height = 600;

        const svg = d3.select(svgRef.current)
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("viewBox", `0 0 ${width} ${height}`)
            .attr("preserveAspectRatio", "xMidYMid meet")

        // create a tooltip to show the countries
        var tooltip = d3.select("#world-map-container")
            .append("div")
            .style("position", "fixed")
            .style("visibility", "hidden")
            .style("background-color", "white")
            .style("border", "1px solid black")
            .style("border-radius", "5px")
            .style("padding", "10px")
            .style("pointer-events", "none")
            .text("#tooltip#");

        // ensure no duplicate maps and tooltip
        svg.selectAll("*").remove();
        tooltip.selectAll("*").remove();

        const projection = d3.geoMercator()
            .scale(150)
            .translate([width / 2, height / 1.5]);

        const path = d3.geoPath().projection(projection);

        const g = svg.append("g");

        d3.json("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson").then(data => {
            g.selectAll("path")
                .data(data.features)
                .enter()
                .append("path")
                .attr("d", path)
                .attr("class", function (d) {
                    return selectedCountries.includes(d.properties.name) ? "country-selected" : "country";
                })
                .attr("stroke", "black")
                .attr("stroke-width", 0.5)
                .on("mouseover", function (event, d) {
                    // show tooltip with country name
                    tooltip.style("visibility", "visible")
                        .text(d.properties.name);
                })
                .on("mousemove", function (event, d) {
                    tooltip
                        .style("visibility", "visible")
                        .style("top", (event.pageY + 10) + "px")
                        .style("left", (event.pageX + 15) + "px")
                })
                .on("mouseout", function (event, d) {
                    tooltip.style("visibility", "hidden");
                })
                .on("mousedown", function (event, d) {
                    tooltip.style("visibility", "hidden");
                })
                .on("mouseup", function (event, d) {
                    tooltip.style("visibility", "visible");
                })
                .on("click", function (event, d) {
                    if (!dynamic) return;
                    handleCountrySelect(d.properties.name);
                    if (d3.select(this).attr("class") === "country-selected") {
                        d3.select(this).attr("class", "country");
                    } else {
                        d3.select(this).attr("class", "country-selected");
                    }
                }
                );
        });
        const zoom = d3.zoom()
            .scaleExtent([1, 8])
            .translateExtent([[0, 0], [width, height]])
            .on('zoom', (event) => {
                g.attr('transform', event.transform);
            });

        svg.call(zoom);
    }, []);

    // Update the classes of the countries without reloading the entire map
    useEffect(() => {
        const svg = d3.select(svgRef.current);
        svg.selectAll("path")
            .attr("class", d => selectedCountries.includes(d.properties.name) ? "country-selected" : "country");
    }, [selectedCountries]); // Only re-run this effect when `selectedCountries` changes

    const downloadGraph = () => {
        var svg = svgRef.current.innerHTML;
        // we need to ensure that the styling found in the classes of each path are transformed into
        // inline stlying so that they appear in the downloaded svg file
        // this needs to be dynamic as the classes can change, we use getComputedStyle to get the styles
        // of the selected elements and then apply them to the inline style of the element
        const svgElement = svgRef.current.cloneNode(true);

        svgElement.innerHTML = svg;
        const paths = svgElement.querySelectorAll('path');
        paths.forEach(path => {
            // get the classes of the path
            const classes = path.classList;
            classes.forEach(c => {
                // now we get the css class element and transfer the styles to the path
                var elem = document.querySelector(`.${c}`);
                const styles = window.getComputedStyle(elem);
                // TODO decide what styles we want to transfer
                path.style.setProperty("fill", styles.getPropertyValue("fill"));
            });
        });
        svg = svgElement.innerHTML;
        var serializer = new XMLSerializer();
        var source = serializer.serializeToString(svgElement);
        const blob = new Blob([source], { type: 'image/svg+xml ' });

        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('download', 'world-map.svg');
        a.setAttribute('href', url);
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    return (<div id='world-map-container' className="world-container">
        {dynamic && <label className="mb-4 fw-bold">{question.question}</label>}
        <svg ref={svgRef} id='svg-world-map'></svg>
        {dynamic &&
            <>
                <div className='d-flex flex-wrap gap-1'>
                    {selectedCountries.length > 0 ? (
                        selectedCountries.map((country, index) => (
                            <p className='bg-primary text-white rounded p-1' key={index}>{country}</p>
                        ))
                    )
                        :
                        <p className='disabled fst-italic'>None</p>}
                </div>
                <button className="btn btn-outline-primary" onClick={downloadGraph}>Download</button>
            </>
        }
    </div>);
};

export default WorldMap;
