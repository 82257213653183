import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';
import Filters from './utils/MapFilters';

const Map = ({ legends, countryLayers, height, includeFilters = true }) => {
    const [activeLayer, setActiveLayer] = useState({});
    const [activeLegend, setActiveLegend] = useState(legends[1]);

    useEffect(() => {
        // Add all layers to the activeLayer state
        const initialActiveLayer = {};
        legends.forEach((legend) => {
            initialActiveLayer[legend.name] = false;
        });
        // Set first layer to active
        initialActiveLayer[legends[0].name] = true;
        setActiveLayer(initialActiveLayer);
    }, [legends]);

    useEffect(() => {
        // Set the active legend
        const newActiveLegend = legends.find((legend) => activeLayer[legend.name]);
        setActiveLegend(newActiveLegend);
    }, [activeLayer, legends]);

    const MoveControls = () => {
        const map = useMap();
        // Set zoom control to be in the top right
        map.zoomControl.setPosition('topright');
        return null;
    };

    return (
        <MapContainer
            center={[50.295, 11.772]}
            zoom={4}
            style={{ height: height || '600px' }}
            className="leaflet-map-container"
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {countryLayers.find((layer) => activeLayer[layer.name]) &&
                countryLayers.find((layer) => activeLayer[layer.name]).layers}
            <MoveControls />
            {activeLegend && activeLegend.component}
            {activeLegend && includeFilters && <Filters activeLayer={activeLayer} setActiveLayer={setActiveLayer} />}
        </MapContainer>
    );
};

Map.propTypes = {
    legends: PropTypes.array.isRequired,
    countryLayers: PropTypes.array.isRequired,
    height: PropTypes.string,
};

export default Map;
