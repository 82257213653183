import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';


// css
import './NestedInput.css';

const NestedInput = ({ parentQuestion, childQuestions, id }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle the collapse of sub-questions
    const toggleSubQuestions = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="w-100 border-bottom pb-2">
            <div className="d-flex align-items-center justify-content-between">
                {parentQuestion}
                <button
                    className="btn btn-outline-secondary align-self-start mt-2"
                    type="button"
                    onClick={toggleSubQuestions}
                    aria-expanded={isOpen}
                >
                    {isOpen ? <FaChevronUp /> : <FaChevronDown />}
                </button>
            </div>
            <Collapse in={isOpen}>
                <div className="nested-input-section">
                    {childQuestions.map((component, index) => (
                        <div
                            key={`nested-survery-question-${id}-${index}`}
                            className="d-flex align-items-center justify-content-between">
                            {component}
                        </div>

                    ))}
                </div>
            </Collapse>
        </div>
    );
};

export default NestedInput;
