import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import cloud from 'd3-cloud';
import { createCanvas } from 'canvas';

const WordCloud = ({ words }) => {
    const wordCloudRef = useRef();

    useEffect(() => {

        d3.select(wordCloudRef.current).selectAll('*').remove();

        // Create layout with canvas for rendering
        const layout = cloud()
            .size([400, 300])
            .canvas(() => createCanvas(1, 1))  // This allows D3 to use an off-screen canvas for measurements
            .words(words.map(d => ({ text: d.word, size: d.count })))
            .padding(5)
            .rotate(() => Math.floor(Math.random() * 2) * 90)
            .fontSize(d => d.size)
            .on('end', draw);

        layout.start();

        // Draw the word cloud
        function draw(words) {
            d3.select(wordCloudRef.current)
                .append('svg')
                .attr('width', layout.size()[0])
                .attr('height', layout.size()[1])
                .append('g')
                .attr('transform', `translate(${layout.size()[0] / 2}, ${layout.size()[1] / 2})`)
                .selectAll('text')
                .data(words)
                .enter()
                .append('text')
                .style('font-size', d => `${d.size}px`)
                .style('fill', () => d3.schemeCategory10[Math.floor(Math.random() * 10)])
                .attr('text-anchor', 'middle')
                .attr('transform', d => `translate(${d.x}, ${d.y}) rotate(${d.rotate})`)
                .text(d => d.text);
        }

        // Clean up on component unmount
        return () => {
            d3.select(wordCloudRef.current).selectAll('*').remove();
        };
    }, [words]);

    return (
        <div className='text-center' ref={wordCloudRef}></div>
    );
};

export default WordCloud;
