import React from "react";

const PageHeader = ({ title, sub_header, description }) => {

    return (
        <div className='row mb-4'>
            <h2 className='text-secondary fw-light m-0'>{sub_header}</h2>
            <h2 className='text-primary fw-medium m-0'>{title}</h2>
            <div className="col-lg-12 col-xl-8">
                <p className="m-0">
                    {description}
                </p>
            </div>
        </div>
    );
}

export default PageHeader;