import React, { useState, useEffect } from 'react';
import DonutChart from '../../donut-chart/DonutChart';
import DonutChart_standard from '../../../../assets/chart-configs/DonutChart_standard.json';

import './CountryPopUp.css';

const DonutContent = ({ country }) => (
    <div className="country-pop-up-container">
        <div className='text-start align-self-start'>
            <h5>{country.name}</h5>
            <p className='m-0'>Number of Messages:</p>
            <span className='text-primary fw-bold fs-5'>{country.numberOfMessages}</span>
            <p className='m-0'>Current Exposure Level:</p>
            <span className='text-primary fw-bold fs-5'>{country.exposureLevel}</span>
        </div>
        <DonutChart data={country.narratives} size={"small"} config={DonutChart_standard} />
    </div>
);

const KPIContent = ({ country }) => (
    <div className="country-pop-up-container">
        <div className='text-center align-self-center'>
            <h5>{country.name}</h5>
            <p className='m-0'>Number of Messages:</p>
            <span className='text-primary fw-bold fs-5'>{country.numberOfMessages}</span>
        </div>
    </div>
);

const CountryPopUp = ({ label, country }) => {
    const [content, setContent] = useState(null);
    useEffect(() => {
        switch (label) {
            case 'narratives':
                setContent(<DonutContent country={country} />);
                break;
            default:
                setContent(<KPIContent country={country} />);
                break;
        }
    }, [country]);
    return (
        content
    )
};

export default CountryPopUp;