import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

// CSS
import '../Tooltip.css';

const BarChart = ({ data, yRange = null, size = "large", config }) => {
    const svgRef = useRef();
    let width, height;
    if (size === "small") {
        width = config.container.width_small;
        height = config.container.height_small;
    } else if (size === "large") {
        width = config.container.width_large;
        height = config.container.height_large;
    } else {
        width = 800;
        height = 300;
    }
    const margin = config.container.margin;

    // Initialize visibility state
    const [visibility, setVisibility] = useState(() =>
        Object.fromEntries(data.bars.map((bar) => [bar.label, true]))
    );

    useEffect(() => {
        const svg = d3.select(svgRef.current);

        // Clear previous elements
        svg.selectAll('*').remove();

        // Set up the SVG canvas dimensions
        svg
            .attr('width', '100%')
            .attr('height', '100%')
            .attr(
                'viewBox',
                `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom
                }`
            )
            .attr('preserveAspectRatio', 'none') // Changed to 'none'
            .style('overflow', 'visible');

        // Set up scales
        const xScale = d3.scaleBand()
            .domain(data.bars.map(d => d.label))
            .range([0, width])
            .padding(0.1);

        const yScale = d3.scaleLinear()
            .domain(yRange ? yRange : [0, d3.max(data.bars, d => d.value)])
            .range([height, 0]);

        // ######## X AXIS ########
        const xAxis = d3.axisBottom(xScale);

        // Add x-axis to chart
        const xAxisGroup = svg.append('g')
            .attr('transform', `translate(${margin.left},${height + margin.top})`)
            .style('font-size', config.xAxis.fontSize)
            .call(xAxis);

        xAxisGroup.selectAll('.tick line')
            .remove();

        xAxisGroup.select('.domain')
            .style('stroke-width', config.xAxis.strokeWidth);

        xAxisGroup.selectAll('.tick text')
            .remove();

        // ######## Y AXIS ########
        const yAxis = d3.axisLeft(yScale)
            .ticks(config.yAxis.ticks);

        // Add y-axis to chart
        const yAxisGroup = svg.append('g')
            .style('font-size', config.yAxis.fontSize)
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .call(yAxis);

        yAxisGroup.select('.domain').remove();

        yAxisGroup.selectAll('.tick line').remove();

        yAxisGroup.selectAll('text')
            .style('fill', config.yAxis.fontColor);

        // ####### GRID LINES #######
        // Add grid lines
        svg.append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .call(
                d3.axisLeft(yScale)
                    .tickSize(-width)
                    .tickFormat('')
                    .tickValues(yScale.ticks(config.gridlines.ticks))
            )
            .style('opacity', 0.1)
            .style('stroke-width', '1');

        // ####### HORIZONTAL LINE AT Y=0 ########
        if (yRange && yRange[0] < 0) {
            svg.append('line')
                .attr('stroke', '#adb5bd')
                .attr('stroke-width', 3)
                .attr('y1', yScale(0) + margin.top)
                .attr('y2', yScale(0) + margin.top)
                .attr('x1', margin.left)
                .attr('x2', width + margin.left);
        }

        // ####### TOOLTIP #######
        let tooltip = d3.select('body').select('.barchart-tooltip');
        if (tooltip.empty()) {
            tooltip = d3.select('body').append('div')
                .attr('class', 'barchart-tooltip')
                .style('position', 'absolute')
                .style('display', 'none')
                .style('background-color', config.tooltip.backgroundColor)
                .style('padding', '8px')
                .style('border-radius', config.tooltip.borderRadius)
                .style('pointer-events', 'none')
                .style('color', config.tooltip.fontColor)
                .style('text-align', 'left');
        }

        // Prepare bars data with visibility
        const barsData = data.bars.map((bar) => ({
            ...bar,
            visible: visibility[bar.label],
        }));

        // Bars
        const bars = svg.selectAll('.bar')
            .data(barsData, (d) => d.id);

        bars.enter()
            .append('rect')
            .attr('class', 'bar')
            .merge(bars)
            .attr('x', (d) => xScale(d.label) + margin.left)
            .attr('width', xScale.bandwidth())
            .attr('fill', (d) => d.color)
            .attr('rx', config.bars.ry)
            .attr('y', yScale(0) + margin.top)
            .attr('height', 0)
            .attr('display', (d) => d.visible ? null : 'none')
            .on('mouseover', function (event, d) {
                if (d.visible) {
                    d3.select(this).attr('opacity', config.bars.opacity_hover);
                    tooltip.style('display', 'block')
                        .html(() => {
                            return `
                            <div>
                                <span class="circle" style="background-color: ${d.color}; display: inline-block; width: 15px; height: 15px; border-radius: 50%; margin-right: 6px;"></span>
                                <b>${d.label}:</b> ${d.value}
                            </div>`;
                        });
                }
            })
            .on('mousemove', function (event) {
                tooltip.style('top', (event.pageY - 10) + 'px')
                    .style('left', (event.pageX + 10) + 'px');
            })
            .on('mouseout', function () {
                d3.select(this).attr('opacity', 1);
                tooltip.style('display', 'none');
            })
            .transition()
            .duration(1000)
            .attr('y', (d) => {
                if (d.visible) {
                    if (d.value > 0) {
                        return yScale(d.value) + margin.top;
                    } else {
                        return yScale(0) + margin.top;
                    }
                } else {
                    return yScale(0) + margin.top;
                }
            })
            .attr('height', (d) => {
                if (d.visible) {
                    if (d.value > 0) {
                        return yScale(0) - yScale(d.value);
                    } else {
                        return yScale(d.value) - yScale(0);
                    }
                } else {
                    return 0;
                }
            });

        bars.exit().remove();

        // Cleanup tooltip on unmount
        return () => {
            tooltip.remove();
        };
    }, [data, visibility]); // Add visibility to dependency array

    // Render the legend as HTML elements
    const renderLegend = () => {
        return (
            <div className="legend d-flex flex-row flex-wrap gap-2 mt-2 px-2 justify-content-center">
                {data.bars.map((bar, index) => (
                    <div
                        key={`barchart-question${bar.id}-bar-${index}`}
                        style={{ display: 'flex', alignItems: 'first baseline', marginBottom: '5px' }}>
                        <div
                            style={{
                                width: '20px',
                                height: '12px',
                                borderRadius: '3px',
                                backgroundColor: bar.color,
                                marginRight: '5px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                // Toggle visibility state for this bar
                                setVisibility((prev) => ({ ...prev, [bar.label]: !prev[bar.label] }));
                            }}
                        ></div>
                        <span
                            style={{
                                fontSize: `${config.legend.fontSize}px`,
                                color: config.legend.fontColor,
                                textDecoration: visibility[bar.label] ? 'none' : 'line-through',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                // Toggle visibility state for this bar
                                setVisibility((prev) => ({ ...prev, [bar.label]: !prev[bar.label] }));
                            }}
                        >
                            {bar.label}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className='d-flex flex-column' style={{ flexGrow: 1 }}>
            <svg
                ref={svgRef}
                style={{ flexGrow: 1, width: '100%', height: '100%' }}
            ></svg>
            {renderLegend()}
        </div>
    );
};

export default BarChart;
