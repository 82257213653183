import { auth_api } from './indexAPI';

// API call to log in
export const login_call = async (username, password) => {
    try {
        const response = await auth_api.post('/login/',
            { username, password }
        );
        return response;
    } catch (error) {
        throw error.response?.data || 'Login failed';
    }
};

// API call to log out
export const logout_call = async () => {
    try {
        await auth_api.post('/logout/');
    } catch (error) {
        throw error.response?.data || 'Logout failed';
    }
};

export const auth_status = async () => {
    try {
        const response = await auth_api.get('/status/');
        return response;
    } catch (error) {
        throw error.response?.data || 'Failed to check authentication status';
    }
}