import React from "react";

// Icons
import { HiArrowTrendingUp, HiArrowTrendingDown } from "react-icons/hi2";
import bubble from "../../../assets/images/bubble.svg";


const ExposureLevel = ({ type }) => {

    let content = null;
    switch (type) {
        case 'trending':
            content = (
                <div
                    className="d-flex align-items-center justify-content-center border rounded">
                    <span className="me-1 fst-italic">Trending</span>
                    <HiArrowTrendingUp />
                </div>
            )
            break;
        case 'filter_bubble':
            content = (
                <div
                    className="d-flex align-items-center justify-content-center border rounded">
                    <span className="me-1 fst-italic">Filter Bubble</span>
                    <img src={bubble} style={{ height: "20px" }} alt="bubble" />
                </div>
            )
            break;
        case 'little_interest':
            content = (
                <div
                    className="d-flex align-items-center justify-content-center border rounded">
                    <span className="me-1 fst-italic">Little Interest</span>
                    <HiArrowTrendingDown />
                </div>
            )
            break;
        default:
            content = null;
            break;
    }

    return (
        content
    )
}

export default ExposureLevel;