import React from "react";

const SectionContainer = ({ section_header, children, id }) => {

    return (
        <div
            className='row g-2 border border-primary rounded pb-2 mb-3'
            id={id && id}
        >
            <h5 className="text-primary mt-2">{section_header}</h5>
            {children}
        </div>
    )
}

export default SectionContainer;