import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Pages
import ManualDataPage from '../features/data-collection/pages/ManualDataPage';

const ManualDataRoutes = (props) => {

    return (
        <Routes>
            <Route path="/" element={<>This is a placeholder</>} />
            <Route path="topic1" element={<ManualDataPage {...props} topicId="topic1" />} />
            <Route path="topic2" element={<ManualDataPage {...props} topicId="topic2" />} />
            <Route path="topic3" element={<ManualDataPage {...props} topicId="topic3" />} />
            <Route path="topic4" element={<ManualDataPage {...props} topicId="topic4" />} />
            <Route path="topic5" element={<ManualDataPage {...props} topicId="topic5" />} />
            <Route path="topic6" element={<ManualDataPage {...props} topicId="topic6" />} />
            <Route path="topic7" element={<ManualDataPage {...props} topicId="topic7" />} />
            <Route path="topic8" element={<ManualDataPage {...props} topicId="topic8" />} />
            <Route path="topic9" element={<ManualDataPage {...props} topicId="topic9" />} />
            <Route path="topic10" element={<ManualDataPage {...props} topicId="topic10" />} />
            <Route path="topic11" element={<ManualDataPage {...props} topicId="topic11" />} />
        </Routes>
    );
};

export default ManualDataRoutes;
