import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { fetchProjects, fetchProjectById } from './projectsAPI';

export const getProjects = createAsyncThunk(
    'projects/getProjects',
    async () => {
        const response = await fetchProjects();
        return response.projects;
    }
);

export const getProjectById = createAsyncThunk(
    'projects/getProjectById',
    async (projectId) => {
        const response = await fetchProjectById(projectId);
        return response.project;
    }
);

const projectsSlice = createSlice({
    name: 'projects',
    initialState: {
        byId: {},
        allIds: [],
        selectedProjectId: null,
        loading: false,
        error: null
    },
    reducers: {
        selectProject: (state, action) => {
            state.selectedProjectId = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjects.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.loading = false;
                action.payload.forEach(project => {
                    state.byId[project.id] = project;
                    if (!state.allIds.includes(project.id)) {
                        state.allIds.push(project.id);
                    }
                });
            })
            .addCase(getProjects.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getProjectById.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getProjectById.fulfilled, (state, action) => {
                state.loading = false;
                const project = action.payload;
                state.byId[project.id] = project;
                if (!state.allIds.includes(project.id)) {
                    state.allIds.push(project.id);
                }
            })
            .addCase(getProjectById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const { selectProject } = projectsSlice.actions;
export default projectsSlice.reducer;