// src/App.js
import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Route Wrappers
import PrivateRoute from './app/PrivateRoute';
import ProjectRoute from './app/ProjectRoute';
import NarrativeRoute from './app/NarrativeRoute';

// Components
import SideBar from './components/navigation/sidebar/SideBar';
import TopBar from './components/navigation/topbar/TopBar';
import ManualDataRoutes from './app/ManualDataRoutes';
import Breadcrumb from './components/navigation/breadcrumb/Breadcrumb';

// Pages
import ProjectsNavPage from './features/projects/pages/ProjectsNavPage';
import ProjectPage from './features/projects/pages/ProjectPage';
import DataPage from './features/data-collection/pages/DataPage';
import Report from './features/reports/Report';
import Login from './features/login/Login';
import NarrativesPage from './features/narratives/pages/NarrativesPage';
import DetailedNarrativePage from './features/narratives/pages/DetailedNarrativePage';
import AutomaticDataPage from './features/data-collection/pages/AutomaticDataPage';
import WebscrapersPage from './features/data-collection/pages/WebscrapersPage';

// Context
import { AuthContext } from './states/AuthProvider';
// Question Data
import surveyQuestions from './assets/data/questions.json';

// CSS
import './styles/App.css';

// Utils
import { ComputeDashboardData } from './utils/utils';

// Sample Data
import sample_company_data from './assets/data/sample_data.json';

const App = () => {
  const [companyData, setCompanyData] = useState(sample_company_data);
  const [dashbaordData, setDashboardData] = useState(null);
  const [dataExists, setDataExists] = useState(true);

  // Auth Context
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Router>
      <div className="d-flex flex-column vh-100 overflow-hidden" data-bs-theme="light">
        <TopBar />
        <div className='d-flex flex-grow-1 overflow-auto' >
          {isAuthenticated && <SideBar />}
          <div className="bg-light content flex-grow-1 overflow-auto pb-5" id='content'>
            {isAuthenticated && <Breadcrumb />}
            <Routes>
            /* Routes within the PrivateRoute wrapper require authentication */
              <Route element={<PrivateRoute />}>
              /* TODO: curerntly the "/" page is not used, maybe there should be a section here to select a client */
                <Route path="/*" element={<Navigate to="/projects" replace />} />
                <Route path="/projects/" element={<ProjectsNavPage />} />
                /* Routes within the ProjectRoute wrapper can only be loaded once a project is selected */
                <Route element={<ProjectRoute />}>
                  <Route path="/projects/:company_id" element={<ProjectPage />} />
                  <Route path="/projects/:company_id/narratives" element={<NarrativesPage />} />
                  /* Routes within the NarrativeRoute wrapper can only be loaded once a narrative is selected */
                  <Route element={<NarrativeRoute />}>
                    <Route path="/projects/:company_id/narratives/:narrative_id" element={<DetailedNarrativePage />} />
                  </Route>
                  <Route path="/projects/:company_id/data/manual-collection/*" element={
                    <ManualDataRoutes
                      setCompanyData={setCompanyData}
                      ComputeDashboardData={ComputeDashboardData(setDashboardData, setDataExists, surveyQuestions)} />
                  } />
                  <Route path="/projects/:company_id/risk-report"
                    element={<Report />} />
                  <Route path="/projects/:company_id/data" element={<DataPage />} />
                  <Route path="/projects/:company_id/data/manual-collection" element={<DataPage />} />
                  <Route path="/projects/:company_id/data/automated-collection" element={<AutomaticDataPage />} />
                  <Route path="/projects/:company_id/data/automated-collection/webscrapers" element={<WebscrapersPage />} />
                </Route>
              </Route>
              <Route path="/login" element={<Login />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router >
  );
};

export default App;
