import './styles/custom_bs.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Providers
import { Provider } from 'react-redux';
import { AuthProvider } from './states/AuthProvider';

// Redux Store
import store from './store/store';
// ... your component code


const root = ReactDOM.createRoot(document.getElementById('root'));
// hide overflow in body
document.body.style.overflow = 'hidden';
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
