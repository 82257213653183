import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

import '../Tooltip.css';

const DonutChart = ({ data, size = "large", config }) => {
    const svgRef = useRef(null);
    const isAnimating = useRef(false);

    let width, height;
    if (size === "small") {
        width = config.container.width_small;
        height = config.container.height_small;
    } else if (size === "large") {
        width = config.container.width_large;
        height = config.container.height_large;
    } else {
        width = 800;
        height = 300;
    }
    const radius = Math.min(width, height) / 2;
    const margin = config.container.margin;
    const availableWidth = width / 3;

    // Initialize visibility state
    const [visibility, setVisibility] = useState(() =>
        Object.fromEntries(data.slices.map((slice) => [slice.label, true]))
    );

    useEffect(() => {
        // Create the svg only once
        const svg = d3.select(svgRef.current)
            .attr('width', '100%')
            .attr('height', '100%')
            .attr(
                'viewBox',
                `0 0 ${width} ${radius * 2}`
            )
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .style('overflow', 'visible');

        // Append the donut group
        svg.append('g')
            .attr('class', 'donut-group')
            .attr(
                'transform',
                `translate(${(width) / 2}, ${radius})`
            );

        return () => {
            svg.selectAll('*').remove();
        };
    }, [data]);

    useEffect(() => {
        drawChart();
    }, [data, visibility]);

    const drawChart = () => {
        const svg = d3.select(svgRef.current);
        const donut = svg.select('.donut-group');

        // Arc and pie generators
        const arc = d3
            .arc()
            .innerRadius(radius / 2)
            .outerRadius(radius)
            .cornerRadius(10);

        const pie = d3
            .pie()
            .sort(null)
            .value((d) => d.value)
            .padAngle(0.03);

        // Filter data based on visibility
        const filteredSlices = data.slices.filter((slice) => visibility[slice.label]);
        const chartData = pie(filteredSlices);

        // Update legend styles
        svg.select('.legend')
            .selectAll('.legend-item')
            .select('text')
            .style('text-decoration', (d) => (visibility[d.label] ? 'none' : 'line-through'))
            .style('text-decoration-color', 'black')
            .style('text-decoration-thickness', '3px')

        // Tooltip
        let tooltip = d3.select('body').select('.donut-tooltip');
        if (tooltip.empty()) {
            tooltip = d3
                .select('body')
                .append('div')
                .attr('class', 'donut-tooltip')
                .style('position', 'absolute')
                .style('display', 'none')
                .style('background-color', config.tooltip.backgroundColor)
                .style('padding', '8px')
                .style('border-radius', config.tooltip.borderRadius)
                .style('pointer-events', 'none')
                .style('color', config.tooltip.fontColor)
                .style('text-align', 'left')
                .style('z-index', '1000');
        }

        // Update slices
        const paths = donut.selectAll('path')
            .data(chartData, (d) => d.data.id);

        // ENTER
        paths.enter()
            .append('path')
            .attr('class', (d, i) => `slice-${d.data.id}-${i}`)
            .attr('fill', (d) => d.data.color)
            .each(function (d) {
                // Initialize the starting angles to have zero size
                const startAngle = d.startAngle;
                this._current = { ...d, startAngle: startAngle, endAngle: startAngle };
            })
            .on('mouseover', function (event, d) {
                if (!isAnimating.current) {
                    const [x, y] = arc.centroid(d);
                    // In the case that there is only one slice visible, it wouldnt make sense
                    // to move the slice, so we prevent it
                    console.log(chartData)
                    if (chartData.length === 1) {
                        return;
                    }
                    d3.select(this)
                        .transition()
                        .duration(config.slices.animation_hover.duration)
                        .attr(
                            'transform',
                            `translate(${x * config.slices.animation_hover.distance}, ${y * config.slices.animation_hover.distance})`
                        );
                }

                tooltip.style('display', 'block').html(() => {
                    return `
                        <div>
                            <span class="circle" style="background-color: ${d.data.color}; display: inline-block; width: 15px; height: 15px; border-radius: 50%; margin-right: 6px;"></span>
                            <b>${d.data.label}:</b> ${d.data.value}
                        </div>`;
                });
            })
            .on('mousemove', function (event) {
                tooltip
                    .style('top', event.pageY - 10 + 'px')
                    .style('left', event.pageX + 10 + 'px');
            })
            .on('mouseout', function () {
                if (!isAnimating.current) {
                    d3.select(this)
                        .transition()
                        .duration(config.slices.animation_hover.duration)
                        .attr('transform', `translate(0, 0)`);
                }
                tooltip.style('display', 'none');
            })
            // Merge with UPDATE selection
            .merge(paths)
            .transition()
            .duration(config.slices.animation_start.duration)
            .attrTween('d', function (d) {
                const interpolate = d3.interpolate(this._current, d);
                this._current = interpolate(1);
                return (t) => arc(interpolate(t));
            })
            .on('start', () => {
                isAnimating.current = true;
            })
            .on('end', () => {
                isAnimating.current = false;
            });

        // EXIT
        paths.exit()
            .transition()
            .duration(config.slices.animation_start.duration)
            .attrTween('d', function () {
                const endAngle = this._current.endAngle;
                const interpolate = d3.interpolate(this._current, { ...this._current, startAngle: endAngle });
                return (t) => arc(interpolate(t));
            })
            .remove();
    };

    // Render the legend as HTML elements
    const renderLegend = () => {
        return (
            <div className="legend d-flex flex-column flex-wrap gap-2 mt-2 px-2" style={{ flex: "33.33%" }}>
                {data.slices.map((slice, index) => (
                    <div
                        key={`donutchart-legend-${slice.id}-slice-${index}`}
                        style={{ display: 'flex', alignItems: 'first baseline', marginBottom: '5px' }}
                        onClick={() => setVisibility((prev) => {
                            // We dont want to prevent the case where there no more 
                            // visible slices
                            if (Object.values(prev).filter((v) => v).length === 1 && prev[slice.label]) {
                                return prev;
                            }
                            return ({ ...prev, [slice.label]: !prev[slice.label] })
                        }
                        )}>
                        <div
                            style={{
                                width: '20px',
                                height: '12px',
                                borderRadius: '3px',
                                backgroundColor: slice.color,
                                marginRight: '5px',
                                cursor: 'pointer',
                            }}
                        ></div>
                        <span
                            style={{
                                fontSize: `${config.legend.fontSize}`,
                                color: config.legend.fontColor,
                                textDecoration: visibility[slice.label] ? 'none' : 'line-through',
                                textDecorationColor: visibility[slice.label] ? 'none' : config.legend.fontColor,
                                textDecorationThickness: visibility[slice.label] ? 'none' : '3px',
                                cursor: 'pointer',
                            }}
                        >
                            {slice.label}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className='px-0 py-3' style={{ display: 'flex', alignItems: 'flex-start' }}>
            {renderLegend()}
            <svg ref={svgRef} style={{ flex: "66.66%" }}></svg>
        </div>
    );
};

export default DonutChart;
