import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import { useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const MapLegendContent = ({ title, content }) => (
    <div className="bg-white rounded shadow-sm p-2" style={{ width: '250px' }}>
        <h4 className="text-secondary fw-bolder fs-5">{title}</h4>
        {content.map((item, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
                <div
                    className="me-2"
                    style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '5px',
                        backgroundColor: item.color,
                    }}
                />
                <span>{item.name}</span>
            </div>
        ))}
    </div>
);

const MapLegend = ({ title, content }) => {
    const map = useMap();
    const legendControl = useRef(null);
    const rootRef = useRef(null);

    useEffect(() => {
        if (!legendControl.current) {
            legendControl.current = L.control({ position: 'topleft' });

            legendControl.current.onAdd = function () {
                const div = L.DomUtil.create('div', 'legend');
                rootRef.current = createRoot(div);
                return div;
            };

            legendControl.current.addTo(map);
        }

        // Cleanup function to remove the legend when the component unmounts
        return () => {
            if (legendControl.current) {
                map.removeControl(legendControl.current);
                legendControl.current = null;
                rootRef.current = null;
            }
        };
    }, [map]);

    useEffect(() => {
        if (rootRef.current) {
            rootRef.current.render(<MapLegendContent title={title} content={content} />);
        }
    }, [title, content]);

    return null;
};

MapLegendContent.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
        })
    ).isRequired,
};

MapLegend.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default MapLegend;
