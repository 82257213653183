// These functions are here to make the narrative page less cluttered
// I will try and make these functions more generic so that there
// doesnt have to be a function for each layer

export const messagesData = (byId, byRegion) => {
    return Object.values(byRegion.byId).map(region => {
        return {
            id: region.id,
            name: region.name,
            numberOfMessages: region.numberOfMessages,
            exposureLevel: region.exposureLevel,
            color: () => {
                if (region.numberOfMessages >= 5000) {
                    return 'red';
                } else if (region.numberOfMessages >= 300) {
                    return 'orange';
                } else if (region.numberOfMessages >= 100) {
                    return 'yellow';
                } else {
                    return 'green';
                }
            }
        };
    });
}

export const narrativesData = (byId, byRegion) => {
    return Object.values(byRegion.byId).map(region => {
        return {
            id: region.id,
            name: region.name,
            numberOfMessages: region.numberOfMessages,
            exposureLevel: region.exposureLevel,
            narratives: {
                slices: region.narratives.map(narrative => ({
                    id: `region${region.id}_narrative${narrative.narrativeId}`,
                    value: narrative.spreadPercentage,
                    label: byId[narrative.narrativeId].name,
                    color: byId[narrative.narrativeId].tag
                })),
            }
        };
    });
}