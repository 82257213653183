import React, { createContext, useState, useEffect } from 'react';

// import api routes
import { login_call, logout_call, auth_status } from '../api/authAPI';

// Spinner
import Spinner from 'react-bootstrap/Spinner';

// Create the AuthContext
export const AuthContext = createContext();

// Create the AuthProvider component that will wrap your app
export const AuthProvider = ({ children }) => {
    // State to hold authentication data
    const [user, setUser] = useState(null);
    const [checkingAuth, setCheckingAuth] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // Function to handle user login
    const login = async (username, password) => {
        setLoading(true);
        const random = Math.random();
        setTimeout(async () => {
            try {
                const response = await login_call(username, password);
                if (response.data.message === 'Logged in successfully') {
                    setUser({ username });
                    setIsAuthenticated(true);
                } else {
                    setError(response.data.error);
                }
            } catch (error) {
                console.error('Login failed:', error);
                setError(error.error);
            }
            setLoading(false);
        }, random * 1000);
    };

    // Function to handle user logout
    const logout = async () => {
        try {
            await logout_call();
            setUser(null);
            setIsAuthenticated(false);
        } catch (error) {
            console.error('Logout failed:', error);
            setError(error.error);
        }
    };

    // Function to check if the user is authenticated by making a request to Django
    const checkAuthStatus = async () => {
        try {
            const response = await auth_status();
            if (response.data.isAuthenticated) {
                setUser(response.data.user);
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        } catch (error) {
            console.error('Error checking authentication status:', error);
            setIsAuthenticated(false);
        }
        setLoading(false);
        setCheckingAuth(false);
    };

    // Check if the user is already authenticated by checking for an auth token (or session)
    useEffect(() => {
        checkAuthStatus();  // Check the authentication status
    }, []);

    // Provide the login, logout, and auth state to the children components
    return (
        <AuthContext.Provider value={{ user, isAuthenticated, loading, error, login, logout }}>
            {!checkingAuth ?
                children
                :
                <div className="d-flex align-items-center justify-content-center vh-100">
                    <Spinner
                        className='me-2 text-secondary'
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true" />
                    <span className="text-secondary">Checking your authentication status...</span>
                </div>
            }
        </AuthContext.Provider>
    );
};
