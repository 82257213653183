import React from 'react';
import { HiArrowLongRight } from "react-icons/hi2";

import './KPI.css';

const KPI = ({ title, value, unit = "", description = "", icon = null, link, col }) => {
    const content = (
        <div className="d-flex flex-column bg-white border border-primary rounded h-100 p-3">
            <div className="kpi-header text-break">
                {icon && <div className="kpi-icon">{icon}</div>}
                <h5 className="m-0">{title}</h5>
            </div>
            <div className="text-primary fs-4 fw-bold mt-auto text-break">
                {value}<span className="kpi-unit"> {unit}</span>
            </div>
            {description &&
                <div className="text-break">
                    <p className="kpi-description">{description}</p>
                </div>
            }
            <div
                style={{ opacity: link ? 1 : 0 }}
                className='border-top kpi-details-container mt-2'
                onClick={link}>
                <span className='kpi-details-text'>View Details</span> <HiArrowLongRight className='kpi-details-arrow' />
            </div>

        </div>
    );

    return (
        col ?
            <div className={col}>{content}</div>
            :
            content
    );
};

export default KPI;
