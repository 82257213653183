import React, { useRef, useEffect, useState } from 'react';
// Styles
import './RangeSlider.css';

const RangeSlider = ({ question, options, value, onChange }) => {
    const rangeInputRef = useRef(null);
    const [values, setValues] = useState(Object.values(options).map(option => option.value));
    const [labels, setLabels] = useState(Object.values(options).map(option => option.label));

    // Function to update the tooltip and range input properties
    const updateTooltipAndRange = (currentValue) => {

        // update the "progess" bar in the range slider
        const percentage = (currentValue / rangeInputRef.current.max) * 100;
        rangeInputRef.current.style.setProperty('--range-fill-percentage', `${percentage}%`);
        rangeInputRef.current.value = currentValue;

        // update tooltip position and value
        const tooltip = document.getElementById(`tooltip_${question.id}`);
        const min = rangeInputRef.current.min ? rangeInputRef.current.min : 0;
        const max = rangeInputRef.current.max ? rangeInputRef.current.max : 100;
        const newVal = Number(((currentValue - min) * 100) / (max - min));
        tooltip.innerHTML = labels[currentValue];

        const thumbWidth = 32;
        const offset = (thumbWidth) * ((50 - newVal) / 100);
        tooltip.style.left = `calc(${newVal}% + ${offset}px)`;
    };

    useEffect(() => {
        const handleInputChange = () => {
            if (!rangeInputRef.current) return;

            const currentValue = rangeInputRef.current.value;
            updateTooltipAndRange(currentValue);

            onChange(values[currentValue], question.id);
        };

        if (rangeInputRef.current) {
            rangeInputRef.current.addEventListener('input', handleInputChange);
        }

        return () => {
            if (rangeInputRef.current) {
                rangeInputRef.current.removeEventListener('input', handleInputChange);
            }
        };
    }, []);

    useEffect(() => {
        if (!rangeInputRef.current) return;

        // we set the values for the range slider
        // for this we extract the value elements from the dict in the options
        const newValues = Object.values(options).map(option => option.value);
        setValues(newValues);

        // we do the same thing for the labels, although this will mostlikely never happen
        const newLabels = Object.values(options).map(option => option.label);
        setLabels(newLabels);

        // this ensures that when we mount the component and the starting value is "" (no answer yet), 
        // the range slider doesnt move to the first value and assign taht value to the questionaire
        if (value === "") {
            // "" implies there is no answer yet, so we set the default to the min value in the range
            const currentValue = rangeInputRef.current.min;
            updateTooltipAndRange(currentValue);
            return;
        }

        const currentValue = values.indexOf(value);
        updateTooltipAndRange(currentValue);
    }, [value]);

    const handleRangeHover = (e) => {
        let tooltip = document.getElementById(`tooltip_${question.id}`);
        tooltip.style.display = 'block';
    };

    const handleRangeOut = (e) => {
        let tooltip = document.getElementById(`tooltip_${question.id}`);
        tooltip.style.display = 'none';
    };

    return (
        <div className="d-flex align-self-start range-mobile">
            <div className='text-start col-4'>
                <label htmlFor="customRange2" className="form-label fw-bolder">{question.question}</label>
                <p className=' text-subtext'>This is a sample description for this question and should be added. This is a sample description for this question and should be added. This is a sample description for this question and should be added.</p>
            </div>
            <div className="d-flex col-8 px-3 align-items-center">
                <span className="min-value">{labels[0]}</span>
                <div className="range-wrap mx-3">
                    <input type="range" ref={rangeInputRef} onMouseOver={handleRangeHover} onMouseOut={handleRangeOut} className="form-range" defaultValue={value === "" ? 0 : value} min="0" max={options.length - 1} id="customRange2" />
                    <output id={`tooltip_${question.id}`} className="tooltip-range">{value === "" ? 0 : value}</output>
                </div>
                <span className="max-value">{labels[labels.length - 1]}</span>
            </div>
        </div>
    );
};

export default RangeSlider;