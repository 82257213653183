import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { HiPlus } from "react-icons/hi2";

// Components
import PageHeader from '../../../components/containers/page-header/PageHeader';
import ContentContainer from '../../../components/containers/content-container/ContentContainer';

// Redux
import { useSelector } from 'react-redux';

// Graphs
import Table from '../../../components/graphs/table/Table';
import Map from '../../../components/graphs/map/Map';
import MapLegend from '../../../components/graphs/map/utils/MapLegend';
import MapLayer from '../../../components/graphs/map/utils/MapLayer';
import KPI from '../../../components/graphs/kpi/KPI';

// Columns
import { narrativeColumns } from '../utils/narrativesTableColumns';

// CSS
import './NarrativesPage.css';

// Utils
import { handleScrollToElement } from '../../../utils/utils';
import { narrativesData, messagesData } from '../utils/mapDataUtils';


const legendMessages = [
    { name: '0-100', color: 'green' },
    { name: '100-300', color: 'yellow' },
    { name: '300-500', color: 'orange' },
    { name: '500+', color: 'red' }
];

const legendNarrative = [
    { name: 'Use of consumer data without consent', color: 'orange' },
    { name: 'Bad Product Reliability', color: 'blue' },
    { name: 'Incoming Financial Report', color: 'red' }
];

const NarrativesPage = () => {
    const navigate = useNavigate();

    const { selectedProjectId } = useSelector(state => state.projects);
    const { byId, byRegion } = useSelector(state => state.narratives);

    const narrativeLayerData = useMemo(() => {
        // Perform the transformation that prepares the data for the map
        return narrativesData(byId, byRegion);
    }, [byRegion, byId]);

    const messagesLayerData = useMemo(() => {
        // Perform the transformation that prepares the data for the map
        return messagesData(byId, byRegion);
    }, [byRegion, byId]);

    const narrativeLayer = <MapLayer label={'narratives'} layers={narrativeLayerData} />;
    const narrativesLegend = <MapLegend title='Narratives' content={legendNarrative} />;

    const messagesLayer = <MapLayer label={'messages'} layers={messagesLayerData} />;
    const messagesLegend = <MapLegend title='Number of Messages' content={legendMessages} />;

    const handleAddNarrative = () => {
        console.log('Add Narrative');
    }

    return (
        <div className='container'>
            <PageHeader
                title='Narratives'
                description={'Narratives are overarching themes or messages that emerge across different communication channels. In the context of disinformation, identifying narratives is crucial for understanding the intent, scope, and impact of misinformation campaigns.'} />
            <div className='row g-2'>
                <KPI
                    title='Total Identified Narratives'
                    value={Object.keys(byId).length}
                    link={() => handleScrollToElement('all-identified-narratives-container')}
                    col={'col-md-6 col-xl-3'}
                />
                <KPI
                    title='Top Trending Narrative'
                    value={'Use of consumer data without consent'}
                    link={() => navigate(`/projects/${selectedProjectId}/narratives/1`)}
                    col={'col-md-6 col-xl-3'}
                />
                <KPI
                    title='Total Platforms Monitored'
                    value={4}
                    link={() => navigate(`/projects/${selectedProjectId}/data/automated-collection`)}
                    col={'col-md-6 col-xl-3'}
                />
                <KPI
                    title='Number of Regions'
                    value={9}
                    link={() => handleScrollToElement('narratives-spread-by-region-container')}
                    col={'col-md-6 col-xl-3'}
                />
                <ContentContainer title='All Identified Narratives List' col={'col-12'} id={'all-identified-narratives-container'} >
                    <div className='row'>
                        <div className='col-md-12 col-lg-8'>
                            <p>
                                These are all identified narratives in the system. You can add new narratives to the system by clicking the button below. By clicking on the narrative name, you can view the details of the narrative.
                            </p>
                        </div>
                        <div className='col-md-12 col-lg-4 text-end'>
                            <button className='btn btn-outline-primary ' onClick={handleAddNarrative}><HiPlus />Add Narrative</button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <Table
                            rows={Object.values(byId)}
                            columns={narrativeColumns(selectedProjectId, navigate)} />
                    </div>
                </ContentContainer>
                <ContentContainer title='Spread by Region' col={'col-12'} id={'narratives-spread-by-region-container'}>
                    <p className='col-md-12 col-lg-8'>
                        This map shows the spread of narratives by region. By clicking on a region, you can view the narratives that are spread in that region.
                    </p>
                    <Map
                        legends={[
                            { name: "Narratives", component: narrativesLegend },
                            { name: "Number of Messages", component: messagesLegend }
                        ]}
                        countryLayers={[
                            { name: "Narratives", layers: narrativeLayer },
                            { name: "Number of Messages", layers: messagesLayer }
                        ]}
                        includeFilters={true}
                    />
                </ContentContainer>
            </div>
        </div>
    )
};

export default NarrativesPage;
