import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const MapFiltersContent = ({ activeLayer, setActiveLayer }) => {

    const onFilterChange = (e) => {
        const value = e.target.value;
        const newActiveLayer = {};
        Object.keys(activeLayer).forEach(layer => {
            newActiveLayer[layer] = false;
        });
        newActiveLayer[value] = true;
        setActiveLayer(newActiveLayer);
    };

    return (
        <div className="bg-primary rounded shadow-sm p-2" style={{ width: "250px" }}>
            <h4 className='text-white fw-bolder fs-5'>Filters</h4>
            <div key={"narratives-map-active-layer"}>
                <label
                    htmlFor={"active-layer"}
                    className='form-label text-white'
                    style={{ fontSize: "1rem" }}>
                    Displayed Layer
                </label>
                <select
                    className='form-select'
                    id={"active-layer"}
                    value={Object.keys(activeLayer).find(layer => activeLayer[layer])}
                    onChange={(e) => onFilterChange(e)}
                >
                    {Object.keys(activeLayer).map(layer => (
                        <option key={layer} value={layer}>{layer}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};

const MapFilters = ({ activeLayer, setActiveLayer }) => {
    const map = useMap();
    const filtersControl = useRef(null);
    const rootRef = useRef(null);

    useEffect(() => {
        if (!filtersControl.current) {
            filtersControl.current = L.control({ position: 'topleft' });

            filtersControl.current.onAdd = function () {
                const div = L.DomUtil.create('div', 'filter');
                rootRef.current = createRoot(div);
                return div;
            };

            filtersControl.current.addTo(map);
        }

        // Cleanup function to remove the filters when the component unmounts
        return () => {
            if (filtersControl.current) {
                map.removeControl(filtersControl.current);
                filtersControl.current = null;
                rootRef.current = null;
            }
        };
    }, [map]);

    useEffect(() => {
        if (rootRef.current) {
            rootRef.current.render(
                <MapFiltersContent activeLayer={activeLayer} setActiveLayer={setActiveLayer} />
            );
        }
    }, [activeLayer, setActiveLayer]);

    return null;
};

MapFiltersContent.propTypes = {
    activeLayer: PropTypes.object.isRequired,
    setActiveLayer: PropTypes.func.isRequired,
};

MapFilters.propTypes = {
    activeLayer: PropTypes.object.isRequired,
    setActiveLayer: PropTypes.func.isRequired,
};


export default MapFilters;
