import React, { useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

import { HiOutlineTable, HiOutlinePresentationChartBar } from 'react-icons/hi';
import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2';

// Components
import ContentContainer from '../../../components/containers/content-container/ContentContainer';
import PageHeader from '../../../components/containers/page-header/PageHeader';

// Graphs  
import Map from '../../../components/graphs/map/Map';
import MapLegend from '../../../components/graphs/map/utils/MapLegend';
import MapLayer from '../../../components/graphs/map/utils/MapLayer';
import Table from '../../../components/graphs/table/Table';
import DonutChart from '../../../components/graphs/donut-chart/DonutChart';
import LineChart from '../../../components/graphs/line-chart/LineChart';

// Chart Config
import DonutChart_standard from '../../../assets/chart-configs/DonutChart_standard.json';
import LineChart_standard from '../../../assets/chart-configs/LineChart_standard.json';
// Columns
import { narrativeColumns } from '../../narratives/utils/narrativesTableColumns';

// Demo Data for now
const legendContent = [
    { name: 'Locations', color: 'blue' },
];

const countryLayers = [{
    id: 1,
    name: 'Belgium',
},
{
    id: 2,
    name: 'Netherlands',
},
{
    id: 3,
    name: 'Switzerland',
},
{
    id: 4,
    name: 'Poland',
},
{
    id: 5,
    name: 'Czech Republic',
},
{
    id: 6,
    name: 'Slovakia',
},
{
    id: 7,
    name: 'Hungary',
},
{
    id: 9,
    name: 'Croatia',
},
{
    id: 10,
    name: 'Germany',
}]

const employementChanges = {
    xValues: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    yValues: [
        {
            id: 97,
            label: 'Hires',
            data: [30, 40, 35, 50, 55, 60, 45, 50, 65, 70, 60, 50],
            color: '#395C6B'
        },
        {
            id: 98,
            label: 'Resignations',
            data: [15, 20, 18, 25, 22, 28, 24, 26, 30, 35, 33, 25],
            color: '#80A4ED'
        },
        {
            id: 99,
            label: 'Terminations',
            data: [5, 8, 6, 10, 12, 15, 10, 12, 14, 16, 12, 10],
            color: '#BCD3F2'
        }
    ]
};

const commChannel = {
    slices: [
        { id: 27, value: 45, label: 'Company Blogs', color: '#395C6B' },
        { id: 26, value: 30, label: 'News Publications', color: '#80A4ED' },
        { id: 28, value: 15, label: 'Twitter', color: '#9EBCF0' },
        { id: 28, value: 10, label: 'Other', color: '#BCD3F2' },
    ]
};

const ProjectPage = () => {
    const { byId, selectedProjectId } = useSelector(state => state.projects);
    const narratives_byId = useSelector(state => state.narratives.byId);
    const loadingProject = useSelector(state => state.projects.loading);
    const navigate = useNavigate();



    if (selectedProjectId !== null && !loadingProject) {
        return (
            <div className='container'>
                <PageHeader title='Project Overview' sub_header={byId[selectedProjectId].name} />
                <div className="row g-2">
                    <div className='col-12'>
                        <ContentContainer title='Client Summary'>
                            <div className='d-flex row'>
                                <div className='d-flex flex-column  col-lg-12 col-xl-7 text-center px-3'>
                                    {Object.entries(byId[selectedProjectId].client_summary).map(([key, value]) => {
                                        return (
                                            <div className='d-flex justify-content-between border-bottom pt-2'>
                                                <div className='text-start p-0 w-50'>
                                                    <h5 className='text-muted'>{key}</h5>
                                                </div>
                                                <div className='text-start w-50'>
                                                    {value}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='col-lg-12 col-xl-5 text-start'>
                                    <Map
                                        legends={[
                                            { name: "Locations", component: <MapLegend title='Locations' content={legendContent} /> },
                                        ]}
                                        countryLayers={[
                                            { name: "Locations", layers: <MapLayer label={'locations'} layers={countryLayers} withPopUp={false} /> }
                                        ]}
                                        height={"400px"}
                                        includeFilters={false}
                                    />
                                </div>
                            </div>
                            <div className='d-flex row mt-3'>
                                <div className='col-lg-12 col-xl-7'>
                                    <h4 className='text-primary text-start'>Change of Employement Structure</h4>
                                    <p>
                                        This chart shows the change in the employement structure over the year. It shows the number of hires, resignations and terminations.
                                    </p>
                                    <LineChart data={employementChanges} size={"large"} config={LineChart_standard} />
                                </div>
                                <div className='col-lg-12 col-xl-5'>
                                    <h4 className='text-primary text-start'>Main Communication-Channels (%)</h4>
                                    <DonutChart data={commChannel} size={"small"} config={DonutChart_standard} />
                                    <p>
                                        This chart shows the main communication channels used by the company. It shows the percentage of communication through different channels.
                                    </p>
                                </div>
                            </div>

                        </ContentContainer>
                    </div>
                    <div className="col-lg-12 col-xl-6">
                        <ContentContainer title='Identified Narratives'>
                            <Table
                                rows={Object.values(narratives_byId)}
                                columns={narrativeColumns(selectedProjectId, navigate)} />
                            <button
                                className="btn btn-outline-primary mt-3"
                                onClick={() => navigate(`/projects/${selectedProjectId}/narratives`)}>
                                Go to <HiOutlineChatBubbleLeftRight />All Narratives
                            </button>
                        </ContentContainer>
                    </div>
                    <div className="col-lg-6 col-xl-3">
                        <ContentContainer title='Data Collection'>
                            <p className="text-muted">
                                This section is used to collect data from the employees. This data can be used to identify areas of legal risk and inform compliance efforts.
                            </p>
                            <button
                                className="btn btn-outline-primary mt-auto"
                                onClick={() => navigate(`/projects/${selectedProjectId}/data`)}>
                                Go to <HiOutlineTable />Data Collection
                            </button>
                        </ContentContainer>
                    </div>
                    <div className="col-lg-6 col-xl-3">
                        <ContentContainer title='Risk Report'>
                            <p className="text-muted">
                                This section is used to display the risk report for the company. This data can be used to identify areas of legal risk and inform compliance efforts.
                            </p>
                            <button
                                className="btn btn-outline-primary mt-auto"
                                onClick={() => navigate(`/projects/${selectedProjectId}/risk-report`)}>
                                Go to <HiOutlinePresentationChartBar />Risk Report
                            </button>
                        </ContentContainer>
                    </div>
                </div >
            </div >
        )
    }
};

export default ProjectPage;
