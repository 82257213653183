import React, { useEffect } from 'react';
import { Outlet, Navigate, useParams } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Reducer
import { selectProject, getProjectById } from '../features/projects/projectsSlice';

const ProjectRoute = () => {
    const { selectedProjectId } = useSelector(state => state.projects);
    const dispatch = useDispatch();
    const { company_id } = useParams();

    useEffect(() => {
        if (company_id && selectedProjectId !== company_id) {
            dispatch(selectProject(company_id));
            dispatch(getProjectById(company_id));
        }
    }, [company_id, dispatch]);

    return <Outlet />;
};

export default ProjectRoute;