import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { TiChevronRight } from 'react-icons/ti';
import './Breadcrumb.css';
import { useSelector } from 'react-redux';
import { getMenuItems } from '../../../app/routes';

const Breadcrumb = () => {
    const location = useLocation();

    // Redux state
    const { byId, selectedProjectId } = useSelector((state) => state.projects);
    const { allIds } = useSelector((state) => state.narratives);
    const byIdNarratives = useSelector((state) => state.narratives.byId);
    const { companyData, surveyQuestions } = useSelector((state) => state.data);

    // Theme state
    const [theme, setTheme] = useState('light');

    // Update theme based on the current path
    useEffect(() => {
        if (location.pathname.includes('/risk-report')) {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }, [location]);

    // Generate menu items
    const menuItems = getMenuItems(
        {
            id: selectedProjectId,
            name: byId[selectedProjectId] ? byId[selectedProjectId].name : null,
        },
        companyData,
        surveyQuestions,
        allIds,
        byIdNarratives
    );

    // Flatten menu items into a path map
    const flattenMenuItems = (items) => {
        const pathMap = {};

        const traverse = (items) => {
            items.forEach((item) => {
                pathMap[item.path] = { name: item.name, icon: item.icon };
                if (item.children) {
                    traverse(item.children);
                }
            });
        };

        traverse(items);
        return pathMap;
    };

    const pathMap = flattenMenuItems(menuItems);

    // Generate breadcrumbs based on the current path
    const pathnames = location.pathname.split('/').filter((x) => x);
    let accumulatedPath = '';
    const breadcrumbs = [];

    pathnames.forEach((segment) => {
        accumulatedPath += `/${segment}`;
        if (pathMap[accumulatedPath]) {
            breadcrumbs.push({
                path: accumulatedPath,
                ...pathMap[accumulatedPath],
            });
        } else if (!isNaN(segment)) {
            // Handle dynamic IDs (e.g., project IDs)
            // Optionally, you can fetch the name based on the ID if needed
        } else {
            // For segments not in pathMap, add them with a capitalized name
            breadcrumbs.push({
                path: accumulatedPath,
                name: segment.charAt(0).toUpperCase() + segment.slice(1),
                icon: null,
            });
        }
    });

    return (
        <div className={`breadcrumb ${theme} p-2`}>
            {breadcrumbs.map((item, index) => (
                <div className="d-flex align-items-center" key={item.path}>
                    {index > 0 && <TiChevronRight color="#CECECE" />}
                    <div className="d-flex align-items-center">
                        <Link to={item.path}>
                            <div
                                className={`d-flex align-items-center breadcrumb-content ${theme} text-subtext`}
                            >
                                {item.icon && <div className="me-1">{item.icon}</div>}
                                <div className="m-0">{item.name}</div>
                            </div>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Breadcrumb;
