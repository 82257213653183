import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// Icons
import { HiGlobeAlt, HiOutlineCheckCircle, HiOutlinePencil } from "react-icons/hi2";
import { AiOutlineTwitter, AiFillFacebook, AiFillYoutube } from "react-icons/ai";
import ai_icon from '../../../assets/images/ai_icon.svg';
// Components
import PageHeader from '../../../components/containers/page-header/PageHeader';
import ContentContainer from '../../../components/containers/content-container/ContentContainer';

// Graphs
import Table from '../../../components/graphs/table/Table';

// CSS
import './DataPage.css';

const columns = (selectedProjectId, navigate) => [
    {
        field: 'category', headerName: 'Your Added Topics', width: 300,
        renderCell: (params) => (
            <div
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/projects/${selectedProjectId}/data/manual-collection/${params.row.id}`)}
            >
                {params.value}
            </div>
        )
    },
    {
        field: 'questions', headerName: 'Number Questions', width: 200,
        renderCell: (params) => (
            params.value.length
        )
    },
];

const DataPage = () => {
    const { selectedProjectId } = useSelector(state => state.projects);
    const { surveyQuestions } = useSelector((state) => state.data);
    const navigate = useNavigate();

    const navigateToManualCollection = () => {
        navigate(`/projects/${selectedProjectId}/data/manual-collection/topic11`);
    }

    const navigateToAutomatedCollection = () => {
        navigate(`/projects/${selectedProjectId}/data/automated-collection`);
    }

    return (
        <div className='container'>
            <PageHeader title='Data Collection' />
            <div className="row g-2">
                <div className="col-lg-12 col-xl-6">
                    <ContentContainer title='Manual Collection'>
                        <p className="text-muted">
                            Include data here that should be collected manually. This data can help identify areas of risk. Add Topics that are relevant to your project and add questions to each topic.
                        </p>
                        <Table
                            rows={surveyQuestions}
                            columns={columns(selectedProjectId, navigate)} />
                        <button
                            className="w-100 btn btn-outline-primary mt-3"
                            onClick={navigateToManualCollection}>
                            Go to <HiOutlinePencil />Manual Collection
                        </button>
                    </ContentContainer>
                </div>
                <div className="col-lg-12 col-xl-6">
                    <ContentContainer title='Automated Collection'>
                        <p className="text-muted">
                            Attach data sources that should be collected automatically. These data listeners will collect data from the sources you specify and add it to your project.
                        </p>
                        <div className='row row-cols-1 row-cols-xl-2 g-2'>
                            <div className='col p-2 border rounded data-source-card' onClick={() => navigate(`/projects/${selectedProjectId}/data/automated-collection/webscrapers`)}>
                                <h4 className='text-muted '><HiGlobeAlt />Webscrapers</h4>
                                <p className='fst-italic'>Last updated: recently</p>
                                <p className='text-end'>
                                    Status: <span className='text-success'>active<HiOutlineCheckCircle /></span>
                                </p>
                            </div>
                            <div className='col p-2 border rounded' style={{ cursor: "not-allowed" }}>
                                <h4 className='text-muted '><AiOutlineTwitter />Twitter</h4>
                                <p className='fst-italic'>Last updated: recently</p>
                                <p className='text-end'>
                                    Status: <span className='text-success'>active<HiOutlineCheckCircle /></span>
                                </p>
                            </div>
                            <div className='col p-2 border rounded' style={{ cursor: "not-allowed" }}>
                                <h4 className='text-muted '><AiFillYoutube />YouTube</h4>
                                <p className='fst-italic'>Last updated: 2 days ago</p>
                                <p className='text-end'>
                                    Status: <span className='text-success'>active<HiOutlineCheckCircle /></span>
                                </p>
                            </div>
                            <div className='col p-2 border rounded' style={{ cursor: "not-allowed" }}>
                                <h4 className='text-muted '><AiFillFacebook />Facebook</h4>
                                <p className='fst-italic'>Last updated: 1 day ago</p>
                                <p className='text-end'>
                                    Status: <span className='text-success'>active<HiOutlineCheckCircle /></span>
                                </p>
                            </div>
                        </div>
                        <button
                            className="w-100 btn btn-outline-primary mt-auto"
                            onClick={navigateToAutomatedCollection}>
                            Continue <img src={ai_icon} style={{ height: "22px", verticalAlign: "text-bottom" }} alt='auto-collection' />Automated Collection
                        </button>
                    </ContentContainer>
                </div>
            </div>

        </div >
    )
};

export default DataPage;
