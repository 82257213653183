import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

// CSS
import './Timeline.css';

const messageCount = [
    { date: '2024-04-01', count: 10 },
    { date: '2024-04-10', count: 16 },
    { date: '2024-04-23', count: 11 },
    { date: '2024-05-04', count: 22 },
    { date: '2024-05-15', count: 27 },
    { date: '2024-05-26', count: 18 },
    { date: '2024-05-27', count: 20 },
    { date: '2024-06-18', count: 16 },
    { date: '2024-06-29', count: 25 },
    { date: '2024-07-10', count: 39 },
    { date: '2024-07-21', count: 37 },
    { date: '2024-07-28', count: 35 },
    { date: '2024-08-01', count: 24 },
    { date: '2024-08-14', count: 22 },
    { date: '2024-09-09', count: 19 },
    { date: '2024-09-21', count: 28 },
    { date: '2024-10-17', count: 24 },
    { date: '2024-12-17', count: 21 },
]

const Timeline = ({ startDate, endDate, markers }) => {
    const timelineRef = useRef();

    useEffect(() => {
        // Clear any previous SVG contents
        d3.select(timelineRef.current).selectAll('*').remove();

        const margin = { top: 20, right: 20, bottom: 20, left: 20 };
        const width = 800
        const height = 300

        // Parse the dates
        const parseDate = d3.timeParse('%Y-%m-%d');
        const start = parseDate(startDate);
        const end = parseDate(endDate);

        // Create SVG container
        const svg = d3.select(timelineRef.current)
            .append('svg')
            .attr("width", width)
            .attr("height", height)
            .append('g')
            .attr('transform', `translate(${margin.left}, ${margin.top})`);

        // Create the x scale
        const xScale = d3.scaleTime()
            .domain([start, end])
            .range([0, width]);

        // Create the y scale
        const yScale = d3.scaleLinear()
            .domain([0, d3.max(messageCount, d => d.count)])
            .range([height - margin.bottom - margin.top, 0]);

        // Create the x-axis
        const xAxis = d3.axisBottom(xScale);
        // Draw the x-axis
        svg.append('g')
            .attr('transform', `translate(0, ${height - margin.top - margin.bottom})`)
            .call(xAxis);

        // Create the y-axis
        const yAxis = d3.axisLeft(yScale);

        // Draw the y-axis
        const yAxisGroup = svg.append('g')
            .call(yAxis);

        // Remove the axis line
        yAxisGroup.select('.domain').remove();

        // Optionally, remove the tick lines if you only want the numeric values
        yAxisGroup.selectAll('.tick line').remove();

        // Style the text labels if needed
        yAxisGroup.selectAll('.tick text')
            .style('fill', '#6c757d');


        // ####### GRID LINES #######
        // Add grid lines
        const gridLines = svg.append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(0,0)`)
            .call(
                d3.axisLeft(yScale)
                    .tickSize(-width) // Make the grid lines span the chart width
                    .tickFormat('') // Remove the text from the grid lines
                    .tickValues(yScale.ticks(3)) // Set the number of grid lines
            )
            .style('opacity', 0.1) // Reduce the opacity of the grid lines
            .style('stroke-width', '1'); // Add a dash pattern to the grid lines

        // Draw the message count line
        const line = d3.line()
            .x(d => xScale(parseDate(d.date)))
            .y(d => yScale(d.count))
            .curve(d3.curveMonotoneX);

        svg.append('path')
            .datum(messageCount)
            .attr('fill', 'none')
            .attr('stroke', 'var(--bs-primary)')
            .attr('stroke-width', 2)
            .attr('d', line);

        // Add markers along the timeline
        markers.forEach(marker => {
            const markerDate = parseDate(marker.date);
            if (markerDate) {
                // Add connecting line
                svg.append('line')
                    .attr('x1', xScale(markerDate))
                    .attr('y1', height - margin.top - margin.bottom - 20)
                    .attr('x2', xScale(markerDate))
                    .attr('y2', height - margin.top - margin.bottom)
                    .attr('stroke', 'var(--bs-primary)')
                    .attr('stroke-width', 4);
                svg.append('circle')
                    .attr('cx', xScale(markerDate))
                    .attr('cy', height - margin.top - margin.bottom - 20)
                    .attr('r', 6)
                    .attr('fill', 'var(--bs-primary)');
                // Add text label for each marker
                svg.append('text')
                    .attr('x', xScale(markerDate))
                    .attr('y', height - margin.top - margin.bottom - 30)
                    .attr('text-anchor', 'middle')
                    .attr('fill', 'black')
                    .style('font-size', '12px')
                    .style('font-weight', 'bold')
                    .text(marker.label);
            }
        });

    }, [startDate, endDate, markers]);

    return (
        <div ref={timelineRef}></div>
    );
};

export default Timeline;
