import React from "react";

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Icons
import { AiOutlineTwitter, AiFillFacebook, AiFillYoutube } from "react-icons/ai";

// Graphs
import Table from '../../../components/graphs/table/Table';
import DonutChart from "../../../components/graphs/donut-chart/DonutChart";
import BarChart from "../../../components/graphs/bar-chart/BarChart";
import WordCloud from "../../../components/graphs/word-cloud/WordCloud";
import Timeline from "../../../components/graphs/timeline/Timeline";

// Graph Configs
import DonutChart_standard from '../../../assets/chart-configs/DonutChart_standard.json';
import BarChart_standard from '../../../assets/chart-configs/BarChart_standard.json';

// Columns
import columns from '../utils/messagesTableColumns';

// Components
import PageHeader from '../../../components/containers/page-header/PageHeader';
import SectionContainer from "../../../components/containers/content-container/SectionContainer";
import ContentContainer from '../../../components/containers/content-container/ContentContainer';
import HollowContainer from '../../../components/containers/content-container/HollowContainer';
import ExposureLevel from "../components/ExposureLevel";
import KPI from "../../../components/graphs/kpi/KPI";

// Utils
import { handleScrollToElement } from '../../../utils/utils';

const DetailedNarrativePage = () => {
    const { byId, selectedNarrativeId, messageById } = useSelector(state => state.narratives);

    const formatDonutData = (data) => {
        return ({
            slices: data.map((slice) => ({
                id: `region${slice.name}`,
                value: slice.count,
                label: slice.name,
                color: slice.color
            }))
        })
    };

    const formatBarData = (data) => {
        return ({
            bars: data.map((bar) => ({
                id: bar.id,
                label: bar.name,
                color: bar.color,
                value: bar.count,
            }))
        });
    }

    console.log(byId[selectedNarrativeId].exposureLevel)
    return (
        <div className='container'>
            <PageHeader
                title={byId[selectedNarrativeId].name}
                sub_header={'Narrative'} />
            <div className='row g-2'>
                <SectionContainer section_header='Overview'>
                    <HollowContainer title={"Summary"} col={"col-md-12"}>
                        <div className='row g-2'>
                            <p className="col-md-12 col-xl-4">{byId[selectedNarrativeId].summary}</p>
                            <KPI
                                title='Current Exposure Level'
                                value={<ExposureLevel type={byId[selectedNarrativeId].exposureLevel} />}
                                col={'col-md-6 col-lg-3 col-xl-2 ms-auto'}
                            />
                            <KPI
                                title='Total Messages'
                                value={789}
                                col={'col-md-6 col-lg-3 col-xl-2'}
                                link={() => handleScrollToElement('all-messages-container')}
                            />
                            <KPI
                                title='Main Target Group'
                                value={'Young Professionals'}
                                col={'col-md-6 col-lg-3 col-xl-2'}
                                link={() => handleScrollToElement('targer-audience-container')}
                            />
                            <KPI
                                title='Main Platform'
                                value={<><AiOutlineTwitter />Twitter</>}
                                col={'col-md-6 col-lg-3 col-xl-2 me-auto'}
                                link={() => handleScrollToElement('platform-breakdown-container')}
                            />
                        </div>
                    </HollowContainer>
                    <ContentContainer title={"Associated Keywords"} col={"col-md-12 col-lg-6"}>
                        <p>
                            Keywords associated with the narrative. These keywords are extracted from the messages collected and analyzed by the system.
                        </p>
                        <WordCloud words={byId[selectedNarrativeId].keywords} />
                    </ContentContainer>
                    <ContentContainer title={"Timeline"} col={"col-md-12 col-lg-6"}>
                        <p>
                            Timeline of events related to the narrative. This timeline is based on the messages collected and analyzed by the system.
                        </p>
                        <Timeline
                            startDate='2024-04-01'
                            endDate='2024-11-08'
                            markers={[
                                { date: '2024-05-01', label: 'First Message on Twitter' },
                                { date: '2024-06-27', label: 'Published Report on Privacy Practices' },
                                { date: '2024-09-17', label: 'First minor News Article' },
                            ]}
                        />
                    </ContentContainer>
                </SectionContainer>
                <SectionContainer section_header='Analysis'>
                    <ContentContainer title={"Target Audience"} col={"col-md-12 col-lg-4"} id={'targer-audience-container'}>
                        <DonutChart
                            data={formatDonutData(byId[selectedNarrativeId].targetAudience)}
                            size={"small"}
                            config={DonutChart_standard} />
                        <p>
                            Breakdown of messages by target audience. This chart shows the distribution of messages across different target audiences.
                        </p>
                    </ContentContainer>
                    <ContentContainer title={"Demographics"} col={"col-md-12 col-lg-8"}>
                        <p>
                            Breakdown of messages by demographics. This chart shows the distribution of messages across different demographics.
                        </p>
                        <BarChart
                            data={formatBarData(byId[selectedNarrativeId].demographics)}
                            size="large"
                            config={BarChart_standard} />
                    </ContentContainer>
                    <ContentContainer title={"Platform Breakdown"} col={"col-md-12 col-lg-4"} id={'platform-breakdown-container'}>
                        <DonutChart
                            data={formatDonutData(byId[selectedNarrativeId].platformBreakdown)}
                            size={"small"}
                            config={DonutChart_standard} />
                        <p>
                            Breakdown of messages by platform. This chart shows the distribution of messages across different platforms.
                        </p>
                    </ContentContainer>
                    <ContentContainer title={"Sentiment Analysis of all Messages"} col={"col-md-12 col-lg-8"}>
                        <p>
                            Sentiment analysis of all messages related to the narrative. This chart shows the distribution of sentiment across all messages.
                        </p>
                        <BarChart
                            data={formatBarData(byId[selectedNarrativeId].sentimentAnalysis)}
                            config={BarChart_standard} />
                    </ContentContainer>
                </SectionContainer>
                <SectionContainer section_header='Detailed Data'>
                    <ContentContainer title={"List of all Messages"} col={"col-md-12"} id={'all-messages-container'}>
                        <p>
                            List of all messages related to the narrative. These include automatically collected and manually added messages.
                        </p>
                        <Table rows={Object.values(messageById)} columns={columns} />
                    </ContentContainer>
                </SectionContainer>
            </div>
        </div>
    );
};

export default DetailedNarrativePage;