// src/pages/Dashboard.js
import React, { useState } from 'react';

// Graphs
import LineChart from '../../components/graphs/line-chart/LineChart'
import BarChart from '../../components/graphs/bar-chart/BarChart';
import DonutChart from '../../components/graphs/donut-chart/DonutChart';

// Chart configs
import LineChart_standard from '../../assets/chart-configs/LineChart_standard.json';
import DonutChart_standard from '../../assets/chart-configs/DonutChart_standard.json';
import BarChart_standard from '../../assets/chart-configs/BarChart_standard.json';

// styles
import './Report.css'; // Create a CSS file for styling

const Dashboard = () => {
    const [responses, setResponses] = useState([]);

    const handleSubmitResponses = (newResponses) => {
        setResponses([...responses, newResponses]);
    };

    const employementChanges = {
        xValues: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        yValues: [
            {
                id: 97,
                label: 'Hires',
                data: [30, 40, 35, 50, 55, 60, 45, 50, 65, 70, 60, 50],
                color: '#395C6B'
            },
            {
                id: 98,
                label: 'Resignations',
                data: [15, 20, 18, 25, 22, 28, 24, 26, 30, 35, 33, 25],
                color: '#80A4ED'
            },
            {
                id: 99,
                label: 'Terminations',
                data: [5, 8, 6, 10, 12, 15, 10, 12, 14, 16, 12, 10],
                color: '#BCD3F2'
            }
        ]
    };

    const dismisalls = {
        bars: [
            { id: 30, value: 29, label: 'Dismissals', description: 'Number of Employees that were dismissed', color: '#395C6B' },
            { id: 30, value: 8, label: 'Extraordinary Dismissals', description: 'Number of Employees that were dismissed extraordinary', color: '#80A4ED' },
            { id: 30, value: 3, label: 'Dismissals without Notice', description: 'Number of Employees that were dismissed without notice', color: '#9EBCF0' },
        ]
    };

    const threatsToCompany = {
        bars: [
            { id: 33, value: 4, label: "Damaging the company's reputation", description: 'Threats to harm the company’s public image or reputation.', color: '#395C6B' },
            { id: 33, value: 2, label: 'Threats against specific managers or departments', description: 'Threats aimed at specific individuals or teams within the company.', color: '#80A4ED' },
            { id: 33, value: 2, label: 'Threats to reveal company secrets', description: 'Threats to disclose confidential or sensitive information.', color: '#9EBCF0' },
        ]
    };

    const whistleblowerCases = {
        slices: [
            { id: 42, value: 5, label: 'Corruption', description: 'Cases involving bribery or corruption within the organization or involving external parties.', color: '#9EBCF0' },
            { id: 42, value: 4, label: 'Financial Mis.', description: 'Cases involving fraud, embezzlement, or other financial wrongdoings.', color: '#395C6B' },
            { id: 42, value: 2, label: 'Other', description: 'Any other types of cases not listed above.', color: '#BCD3F2' },
            { id: 42, value: 1, label: 'Discrimination', description: 'Cases involving workplace harassment, sexual harassment, or discrimination based on race, gender, religion, etc.', color: '#80A4ED' },
        ]
    };


    const employeeDemographics = {
        slices: [
            { id: 27, value: 129, label: 'Male', description: 'New employees hired', color: '#395C6B' },
            { id: 26, value: 105, label: 'Female', description: 'Employees resigned', color: '#80A4ED' },
            { id: 28, value: 25, label: 'Non-Binary', description: 'Employees terminated', color: '#9EBCF0' },
            { id: 28, value: 4, label: 'Other', description: 'Employees terminated', color: '#BCD3F2' },
        ]
    };

    return (
        <div className="dashboard px-3 pb-5 letter-spacing" style={{ backgroundColor: "#46556A" }}>
            <div className='container mt-2'>
                <h2 className='text-primary fw-light m-0'>Human Resources</h2>
                <h2 className='text-white fw-medium'>Key Data Summary</h2>
            </div>
            <div className='container mt-3 rounded bg-white p-5'>
                <div className="row">
                    {/* First column: Text and chart for Employee Demographics */}
                    <div className="col-12 col-md-6 mb-4">
                        <h3 className="text-primary">Employee Demographics</h3>
                        <p className="text-muted">
                            This is the demographics data collected from the employees. This data can be used to identify areas of legal risk and inform compliance efforts.
                        </p>
                        <div>
                            <DonutChart data={employeeDemographics} size={"small"} config={DonutChart_standard} />
                        </div>
                    </div>

                    {/* Second column: Text and chart for Employment Changes */}
                    <div className="col-12 col-md-6 mb-4">
                        <h3 className="text-primary">Employment Changes Over Time 2023</h3>
                        <p className="text-muted">
                            This chart shows the number of hires, resignations, and terminations per month. This data can be used to identify trends in employee turnover and inform retention strategies.
                        </p>
                        <LineChart data={employementChanges} size={"small"} config={LineChart_standard} />
                    </div>
                </div>
            </div>
            <div className='container mt-5 rounded bg-white p-5'>
                <div className="row">
                    {/* Group 1: Threats to Company Text and BarChart */}
                    <div className="col-12 col-md-12 mb-5">
                        <h3 className="text-primary">Threats to Company</h3>
                        <p className="text-muted">
                            The types and frequency of threats by employees made to the company. This data can be used to identify areas of legal risk and inform compliance efforts.
                        </p>
                        <BarChart data={threatsToCompany} size={"large"} config={BarChart_standard} />
                    </div>

                    {/* Group 2: Whistleblower Cases Text and DonutChart */}
                    <div className="col-12 col-md-6 mb-4">
                        <h3 className="text-primary">Types of Whistleblower Cases</h3>
                        <p className="text-muted">
                            The following chart displays the number of whistleblower cases reported in the company. This data can be used to identify areas of legal risk and inform compliance efforts.
                        </p>
                        <DonutChart data={whistleblowerCases} size={"small"} config={DonutChart_standard} />
                    </div>

                    {/* Group 3: Employment Dismissals Text and BarChart */}
                    <div className="col-12 col-md-6 mb-4">
                        <h3 className="text-primary">Types of Employment Dismissals in 2023</h3>
                        <p className="text-muted">
                            The following chart displays the number of dismissals, labor lawsuits, and other disputes within the company.
                        </p>
                        <BarChart data={dismisalls} size={"small"} config={BarChart_standard} />
                    </div>
                </div>
            </div>
        </div >
    );

};

export default Dashboard;
