// store.js
import { configureStore } from '@reduxjs/toolkit';
import narrativeReducer from '../features/narratives/narrativesSlice';
import projectsReducer from '../features/projects/projectsSlice';
import globalReducer from '../states/globalSlice';
import dataReducer from '../features/data-collection/dataSlice';

const store = configureStore({
    reducer: {
        narratives: narrativeReducer,
        projects: projectsReducer,
        global: globalReducer,
        data: dataReducer,
    },
});

export default store;
