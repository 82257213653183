import React, { useState, useEffect } from 'react';

import { createRoot } from 'react-dom/client';

import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';

// Components
import CountryPopUp from './CountryPopUp';

// geojson
import geojson from '../../../../assets/data/countries.geo.json';

const MapLayer = ({ label, layers, withPopUp = true }) => {

    // Define the function that handles each feature of the GeoJSON layer
    const onEachFeature = (feature, layer, country) => {
        if (!withPopUp) {
            return;
        }
        // We create a new object to which we bind the custom popup
        var div = L.DomUtil.create('div');
        div.id = `country-popup-${label}-${country.name}`;
        let root = createRoot(div);
        layer.on('click', (e) => {
            // We check if the root is already mounted to avoid trying to create a new one
            if (!root._internalRoot) {
                root = createRoot(div);
            }
            root.render(
                <CountryPopUp label={label} country={country} />
            );
            const minWidth = label === 'narratives' ? 400 : 150;
            // Bind a popup to the clicked feature and display information
            layer.bindPopup(div, {
                maxWidth: 400,
                minWidth: minWidth,
                maxHeight: 200,
                autoPan: true,
                closeButton: true,
            }).openPopup();
        });
        layer.on('popupclose', (e) => {
            // Unmount the root when the popup is closed to remove tooltips etc.
            root.unmount();
            // Remove the div from the DOM
            const div = document.getElementById(`country-popup-${label}-${country.name}`);
            if (div) {
                div.remove();
            }
        });
    };

    return (
        <>
            {layers.map((country, index) => {
                const countryGeoJson = geojson.features.find(feature => feature.properties.ADMIN === country.name);
                // Set the color of the layer
                const color = country.color ? country.color() : 'var(--bs-primary)';
                return (
                    <GeoJSON
                        key={`${label}-${country.name}-layer-${index}`}
                        data={countryGeoJson}
                        style={() => ({
                            color: color,
                            weight: 1,
                            opacity: 1,
                            fillColor: color,
                            fillOpacity: 0.5
                        })}
                        onEachFeature={(feature, layer) => onEachFeature(feature, layer, country)}
                        zoomControl={false} // Disable default zoom control
                    />
                )
            })}
        </>
    );
}

export default MapLayer;