import React, { useEffect, useState } from 'react';

import { FaArrowRight } from 'react-icons/fa';

// Styles
import './CheckBox.css';


const CheckBox = ({ question, options, value, onChange, is_child }) => {
    const [selected, setSelected] = useState(value);

    // we need to create a function that adds and removes values from the value prop
    const handleChange = (option, questionId) => {
        // ensure that the value is a number
        const parsedValue = !isNaN(option) && parseInt(option) ? parseInt(option) : option;

        // if the value is already in the array, we remove it
        if (selected.includes(parsedValue)) {
            const newValue = selected.filter(item => item !== parsedValue);
            onChange(newValue, questionId);
        } else {
            // if the value is not in the array, we add it
            const newValue = [...selected, parsedValue];
            onChange(newValue, questionId);
        }
    }

    useEffect(() => {
        setSelected(value);
    }, [value]);


    return (
        <div className={`w-100 d-flex align-self-start select-mobile ${!is_child ? ' mt-5 pb-3' : 'mt-3 py-1'}`}>
            <div className='text-start col-4 check-text-mobile'>
                <label className={`form-label ${!is_child ? 'fw-bold' : ''}`}>
                    {is_child && <FaArrowRight className='text-primary me-2' />}
                    {question.question}
                </label>
                <p className=' text-subtext'>{question.description}</p>
            </div>
            <div className='d-flex flex-wrap col-8 px-3 align-items-center align-self-start '>
                <div className="row w-100 checkbox-mobile">
                    {options.map((option, index) => (
                        <div key={index} className="col-6 pb-3 d-flex flex-row ">
                            <div className="form-check text-start">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={option.value}
                                    id={`flexCheckDefault_${question.id}_${index}`}
                                    onChange={(e) => handleChange(e.target.value, question.id)}
                                    checked={selected.includes(option.value)}
                                />
                                <div className='ms-3'>
                                    <label className="form-check-label fw-bold" htmlFor={`flexCheckDefault_${question.id}_${index}`}>
                                        {option.label}
                                    </label>
                                    <label className="form-check-label text-subtext" htmlFor={`flexCheckDefault_${question.id}_${index}`}>
                                        {option.description}
                                    </label>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CheckBox;