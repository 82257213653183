import api from '../../api/indexAPI';

export const fetchProjects = async () => {
    // Mock API call
    //const response = await api.get(`/projects/${projectId}/`);
    //return response.data;
    return {
        projects: [
            {
                id: "1",
                name: 'TechSecure',
                description: 'This is a description of the project',
                created_at: '2024-11-02',
                updated_at: '2024-11-05',
                tag: {
                    id: 2,
                    color: 'blue'
                },
                project_manager: {
                    id: 1,
                    name: 'John Doe',
                },
                client_summary: {
                    'Services Or Products': 'Cybersecurity',
                    'Industry': 'Technology',
                    'Client Type': 'B2B',
                    'Leadership Names': 'Jane Smith, Bob Doe',
                    'Client Size': '250',
                    'Client Revenue': '10M',
                    'Client Location': 'Belgium, Netherlands, Switzerland, Poland, Czech Republic, Slovakia, Hungary, Croatia, Germany',
                    'Client Website': 'www.techsecure.com',
                }
            },
        ]
    };
}

export const fetchProjectById = async (projectId) => {
    // Mock API call
    return {
        project: {
            id: "1",
            name: 'TechSecure',
            description: 'This is a description of the project',
            created_at: '2024-11-02',
            updated_at: '2024-11-05',
            tag: {
                id: 2,
                color: 'blue'
            },
            project_manager: {
                id: 1,
                name: 'John Doe',
            },
            client_summary: {
                'Services Or Products': 'Cybersecurity',
                'Industry': 'Technology',
                'Client Type': 'B2B',
                'Leadership Names': 'Jane Smith, Bob Doe',
                'Client Size': '250',
                'Client Revenue': '10M',
                'Client Location': 'Belgium, Netherlands, Switzerland, Poland, Czech Republic, Slovakia, Hungary, Croatia, Germany',
                'Client Website': 'www.techsecure.com',
            }
        }
    };
}
