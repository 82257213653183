import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

const NarrativeRoute = () => {
    const { selectedProjectId } = useSelector(state => state.projects);
    const { selectedNarrativeId } = useSelector(state => state.narratives);

    return selectedNarrativeId ? <Outlet /> : <Navigate to={`/projects/${selectedProjectId}/`} />;
};

export default NarrativeRoute;