// src/components/settingsWindow/SettingsWindow.js
import React, { useState, useEffect } from 'react';
import './SettingsWindow.css';

const SettingsWindow = ({ onClose }) => {
  const [activeTab, setActiveTab] = useState('Account');
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      document.documentElement.classList.add('dark-mode');
      setIsDarkMode(true);
    }
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    document.documentElement.classList.toggle('dark-mode', newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  return (
    <div className="settings-modal-overlay" onClick={onClose}>
      <div className="settings-modal" onClick={(e) => e.stopPropagation()}>
        <div className="settings-header">
          <h2 className="settings-title">Settings</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>

        <nav className="settings-nav">
          <button onClick={() => handleTabClick('Account')} className={activeTab === 'Account' ? 'active' : ''}>Account</button>
          <button onClick={() => handleTabClick('Dashboard')} className={activeTab === 'Dashboard' ? 'active' : ''}>Dashboard</button>
          <button onClick={() => handleTabClick('Storage')} className={activeTab === 'Storage' ? 'active' : ''}>Storage</button>
          <button onClick={() => handleTabClick('General')} className={activeTab === 'General' ? 'active' : ''}>General</button>
          <button onClick={() => handleTabClick('Project Specific')} className={activeTab === 'Project Specific' ? 'active' : ''}>Project Specific</button>
        </nav>

        <div className="settings-content">
          {activeTab === 'Account' && (
            <div>
              <h3>Profile</h3>
              <p>Set up account details.</p>
              <div className="input-group">
                <label>Name</label>
                <input type="text" placeholder="Enter your name" />
              </div>
              <hr className="divider" />
              <div className="input-group">
                <label>Email</label>
                <input type="email" placeholder="Enter your email" />
              </div>
              <hr className="divider" />
            </div>
          )}

          {activeTab === 'Dashboard' && (
            <div>
              <h3>Dashboard Settings</h3>
              <p>Customize your dashboard for risk assessment insights.</p>
              <div className="input-group">
                <label>Default Risk Threshold (%)</label>
                <input type="number" placeholder="e.g., 70" min="0" max="100" />
              </div>
              <hr className="divider" />
              <div className="input-group">
                <label>Preferred Analysis Type</label>
                <select>
                  <option value="sentiment">Sentiment Analysis</option>
                  <option value="source">Source Credibility</option>
                  <option value="frequency">Frequency of Mentions</option>
                </select>
              </div>
              <hr className="divider" />
            </div>
          )}

          {activeTab === 'Storage' && (
            <div>
              <h3>Storage Settings</h3>
              <p>Manage data storage for disinformation tracking.</p>
              <div className="input-group">
                <label>Data Retention Period (days)</label>
                <input type="number" placeholder="e.g., 365" min="1" />
              </div>
              <hr className="divider" />
              <div className="input-group">
                <label>Enable Automatic Cleanup</label>
                <input type="checkbox" />
              </div>
              <hr className="divider" />
            </div>
          )}

          {activeTab === 'General' && (
            <div>
              <h3>General Settings</h3>
              <p>Configure general application preferences.</p>
              <div className="input-group">
                <label>Notification Frequency</label>
                <select>
                  <option value="realtime">Real-time</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                </select>
              </div>
              <hr className="divider" />
              <div className="input-group">
                <label>Language</label>
                <select>
                  <option value="en">English</option>
                  <option value="de">German</option>
                  <option value="es">Spanish</option>
                </select>
              </div>
              <hr className="divider" />
              <div className="input-group">
                <label>Dark Mode</label>
                <button className="toggle-theme-button" onClick={toggleTheme}>
                  {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
                </button>
              </div>
              <hr className="divider" />
            </div>
          )}

          {activeTab === 'Project Specific' && (
            <div>
              <h3>Project Specific Settings</h3>
              <p>Customize settings for specific risk assessment projects.</p>
              <div className="input-group">
                <label>Project Name</label>
                <input type="text" placeholder="Enter project name" />
              </div>
              <hr className="divider" />
              <div className="input-group">
                <label>Keywords to Monitor</label>
                <input type="text" placeholder="Enter keywords separated by commas" />
              </div>
              <hr className="divider" />
              <div className="input-group">
                <label>Regions of Interest</label>
                <input type="text" placeholder="e.g., Europe, North America" />
              </div>
              <hr className="divider" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsWindow;
