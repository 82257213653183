import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

// CSS
import '../Tooltip.css';

const LineChart = ({ data, yRange = null, size = "large", config }) => {
    const svgRef = useRef();

    let width, height;
    if (size === "small") {
        width = config.container.width_small;
        height = config.container.height_small;
    } else if (size === "large") {
        width = config.container.width_large;
        height = config.container.height_large;
    } else {
        width = 800;
        height = 300;
    }
    const margin = config.container.margin;
    const availableWidth = width - margin.left - margin.right;

    // Extract lines data
    const linesData = Object.keys(data.yValues).map((key) => {
        return {
            label: data.yValues[key].label,
            data: data.yValues[key].data,
            color: data.yValues[key].color,
            id: data.yValues[key].id,
        };
    });
    const labels = data.xValues;

    // Initialize visibility state
    const [visibility, setVisibility] = useState(() =>
        Object.fromEntries(linesData.map((line) => [line.label, true]))
    );

    useEffect(() => {
        const svg = d3.select(svgRef.current);

        // Clear previous elements
        svg.selectAll('*').remove();

        // Set up the SVG canvas dimensions
        svg.attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
            .attr('preserveAspectRatio', 'xMidYMid meet')
            .style('overflow', 'visible');

        // Set up scales
        const xScale = d3.scalePoint()
            .domain(labels)
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain(yRange ? yRange : [0, d3.max(linesData, (line) => d3.max(line.data))])
            .range([height, 0]);

        // Create axes
        const xAxis = d3.axisBottom(xScale);
        xAxis.tickValues(xScale.domain().filter((d, i) => !(i % 2))); // Adjust tick frequency as needed

        const xAxisGroup = svg.append('g')
            .attr('transform', `translate(${margin.left},${height + margin.top})`)
            .style('font-size', config.xAxis.fontSize)
            .call(xAxis);

        xAxisGroup.selectAll('.tick line')
            .style('stroke-width', config.xAxis.strokeWidth)
            .attr('stroke-linecap', 'round')
            .style('stroke', config.xAxis.strokeColor);
        xAxisGroup.select('.domain')
            .style('stroke-width', config.xAxis.strokeWidth);
        // Style the text labels if needed
        xAxisGroup.selectAll('.tick text')
            .style('fill', config.xAxis.fontColor);

        const yAxis = d3.axisLeft(yScale)
            .ticks(config.yAxis.ticks); // Adjust tick count as necessary

        const yAxisGroup = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .style('font-size', config.yAxis.fontSize)
            .call(yAxis);
        // Remove the axis line
        yAxisGroup.select('.domain').remove();

        // Optionally, remove the tick lines if you only want the numeric values
        yAxisGroup.selectAll('.tick line').remove();

        // Style the text labels if needed
        yAxisGroup.selectAll('.tick text')
            .style('fill', config.yAxis.fontColor);

        // Line generator function
        const lineGenerator = d3.line()
            .x((d, i) => xScale(labels[i]))
            .y((d) => yScale(d))
            .curve(d3.curveMonotoneX); // smooth line

        // ####### GRID LINES #######
        // Add grid lines
        const gridLines = svg.append('g')
            .attr('class', 'grid')
            .attr('transform', `translate(${margin.left},${margin.top})`)
            .call(
                d3.axisLeft(yScale)
                    .tickSize(-width) // Make the grid lines span the chart width
                    .tickFormat('') // Remove the text from the grid lines
                    .tickValues(yScale.ticks(config.gridlines.ticks)) // Set the number of grid lines
            )
            .style('opacity', 0.1) // Reduce the opacity of the grid lines
            .style('stroke-width', '1'); // Add a dash pattern to the grid lines

        // ####### HORIZONTAL LINE AT Y=0 ########
        // Add horizontal line to the origin for data that goes lower that 0 on the y axis
        if (yRange) {
            if (yRange[0] < 0) {
                const xOriginLine = svg.append('line')
                    .attr('stroke', '#adb5bd')
                    .attr('stroke-width', 3)
                    .attr('y1', yScale(0) + margin.top)
                    .attr('y2', yScale(0) + margin.top)
                    .attr('x1', margin.left) // Initial position (outside the view)
                    .attr('x2', width + margin.left) // Initial position (outside the view)
            }
        }

        // ####### INTERACTIVE VERTICAL LINE #######
        // Create vertical line that will follow the cursor
        const verticalLine = svg.append('line')
            .attr('stroke', '#adb5bd')
            .attr('stroke-width', 1)
            .attr('y1', margin.top)
            .attr('y2', height + margin.top)
            .attr('x1', 0) // Initial position (outside the view)
            .attr('x2', 0) // Initial position (outside the view)
            .style('opacity', 0); // Hidden by default

        // Prepare tooltip content structure
        let tooltipContent = { data: {}, xLabel: null };
        for (let i = 0; i < linesData.length; i++) {
            tooltipContent.data[linesData[i].label] = {
                value: null,
                color: linesData[i].color,
            };
        }

        // ####### DRAW LINES AND CIRCLES #######
        linesData.forEach((line, i) => {
            if (!visibility[line.label]) return; // Skip drawing if not visible

            // Append the lines
            const path = svg.append('path')
                .datum(line.data)
                .attr('class', `line-${line.id}-${i}`)
                .attr('fill', 'none')
                .attr('stroke', line.color)
                .attr('stroke-width', config.lines.strokeWidth)
                .attr('transform', `translate(${margin.left},${margin.top})`)
                .attr('d', lineGenerator);

            const totalLength = path.node().getTotalLength();

            // Set up the initial stroke-dasharray and stroke-dashoffset for the drawing effect
            path
                .attr('stroke-dasharray', totalLength + ' ' + totalLength)
                .attr('stroke-dashoffset', totalLength)
                .transition() // Start the transition
                .duration(config.lines.animation.duration) // Adjust duration for animation speed
                .ease(d3.easePoly) // Linear easing for smooth drawing
                .attr('stroke-dashoffset', 0); // Animate to 0 to draw the line

            // Append circles for interactive tooltips
            const circles = svg.selectAll(`.circle-${line.id}-${i}`)
                .data(line.data)
                .enter()
                .append('circle')
                .attr('class', `circle-${line.id}-${i}`)
                .attr('data-label', line.label)
                .attr('data-xLabel', (d, i) => labels[i]) // Store the x-axis label for the tooltip
                .attr('cx', (d, i) => xScale(labels[i]) + margin.left)
                .attr('cy', (d) => yScale(d) + margin.top)
                .attr('r', config.circles.radius)
                .attr('fill', config.circles.fill)
                .style('stroke', line.color)
                .style('stroke-width', config.circles.strokeWidth);

            // Add text to the circles (optional)
            const circleValues = svg.selectAll(`.text-${line.id}-${i}`)
                .data(line.data)
                .enter()
                .append('text')
                .attr('class', `text-${line.id}-${i}`)
                .attr('x', (d, i) => xScale(labels[i]) + margin.left)
                .attr('y', (d) => yScale(d) + margin.top - 15)
                .text((d) => d)
                .attr('text-anchor', 'middle')
                .style('fill', line.color)
                .style('font-size', '1rem')
                .style('font-weight', 'lighter')
                .style('font-family', 'Arial')
                .style('pointer-events', 'none')
                .style('display', 'none');

            // When the user hovers over the path we want to show the values of each circle
            path
                .on('mouseover', function () {
                    d3.select(this).attr('stroke-width', config.lines.strokeWidth_hover); // Increase line width on hover
                    circleValues.style('display', 'block');
                })
                .on('mouseout', function () {
                    d3.select(this).attr('stroke-width', config.lines.strokeWidth); // Reset line width on mouseout
                    circleValues.style('display', 'none');
                });
        });

        // ####### TOOLTIP #######
        // Create tooltip
        let tooltip = d3.select('body').select('.linechart-tooltip');
        if (tooltip.empty()) {
            tooltip = d3.select('body').append('div')
                .attr('class', 'linechart-tooltip')
                .style('position', 'absolute')
                .style('display', 'none')
                .style('background-color', config.tooltip.backgroundColor)
                .style('padding', '8px')
                .style('border-radius', config.tooltip.borderRadius)
                .style('pointer-events', 'none')
                .style('color', config.tooltip.fontColor)
                .style('text-align', 'left');
        }

        // ####### TOOLTIP FUNCTIONALITY #######
        // Get all circles
        const circles = svg.selectAll('circle');
        // Add mousemove event to move the vertical line with cursor
        svg.on('mousemove', function (event) {
            const [mouseX, mouseY] = d3.pointer(event); // Get the mouse position relative to the SVG
            if (mouseX >= margin.left && mouseX <= width + margin.left && mouseY >= margin.top && mouseY <= height + margin.top) {
                verticalLine
                    .attr('x1', mouseX)
                    .attr('x2', mouseX)
                    .style('opacity', 1); // Make the line visible

                // Counter to check if any of the circles are near the vertical line
                let circlesTouched = 0;
                // Check proximity to each circle
                circles.each(function (d, i) {
                    const cx = Number(d3.select(this).attr('cx')); // X coordinate of each circle
                    const circleLabel = d3.select(this).attr('data-label');
                    if (!visibility[circleLabel]) return; // Skip if the line is not visible
                    if (Math.abs(mouseX - cx) < config.circles.radius_hover) { // Adjust proximity threshold
                        circlesTouched++;
                        d3.select(this).attr('r', config.circles.radius_hover); // Simulate hover effect
                        const circlexLabel = d3.select(this).attr('data-xLabel'); // Get the x-axis label of the circle
                        const circleValue = d; // d is the value of the circle
                        // Update the tooltipContent object with the value of the circle
                        tooltipContent.data[circleLabel] = {
                            ...tooltipContent.data[circleLabel],
                            value: circleValue,
                        };
                        // Add the x-axis label to the tooltip content
                        tooltipContent['xLabel'] = {
                            value: circlexLabel,
                        };
                    } else {
                        d3.select(this).attr('r', config.circles.radius); // Reset the circle radius if not near (hover out)
                    }
                });

                // If any circle is being touched, show the tooltip
                if (circlesTouched > 0) {
                    // Show the tooltip with collected content
                    tooltip.style('display', 'block')
                        .html(() => {
                            const xLabelText = `<b>${tooltipContent.xLabel.value}</b><br/>`;
                            const dataLabelsText = Object.keys(tooltipContent.data).map((key) => {
                                // Only show the data if the "visibility" flag is true and value is not null
                                if (!visibility[key] || tooltipContent.data[key].value === null) return '';
                                return `
                                <div>
                                    <span class="circle" style="background-color: ${tooltipContent.data[key].color}; display: inline-block; width: 15px; height: 15px; border-radius: 50%; margin-right: 6px;"></span>
                                    <b>${key}:</b> ${tooltipContent.data[key].value}
                                </div>`;
                            }).join('');
                            return xLabelText + dataLabelsText;
                        })
                        .style('top', `${event.pageY - 10}px`)
                        .style('left', `${event.pageX + 16}px`);
                } else {
                    // Hide the tooltip if no circles are being touched
                    tooltip.style('display', 'none');
                }

            } else {
                verticalLine.style('opacity', 0); // Hide the line if outside the chart
                tooltip.style('display', 'none'); // Hide the tooltip
                circles.attr('r', config.circles.radius); // Reset circle sizes
            }
        })
            .on('mouseleave', function () {
                verticalLine.style('opacity', 0); // Hide the line if outside the chart
                tooltip.style('display', 'none'); // Hide the tooltip
                circles.attr('r', config.circles.radius); // Reset circle sizes
            });

        // Cleanup the tooltip when component is unmounted
        return () => {
            tooltip.remove();
        };
    }, [data, visibility]); // Add visibility to dependency array

    // Render the legend as HTML elements
    const renderLegend = () => {
        return (
            <div
                className="legend d-flex flex-row flex-wrap gap-2 mt-2 px-2 justify-content-center">
                {linesData.map((line) => (
                    <div key={line.id} style={{ display: 'flex', alignItems: 'first baseline', marginBottom: '5px' }}>
                        <div
                            style={{
                                width: '20px',
                                height: '12px',
                                borderRadius: '3px',
                                backgroundColor: line.color,
                                marginRight: '5px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                // Toggle visibility state for this line
                                setVisibility((prev) => ({ ...prev, [line.label]: !prev[line.label] }));
                            }}
                        ></div>
                        <span
                            style={{
                                fontSize: `${config.legend.fontSize}px`,
                                color: config.legend.fontColor,
                                textDecoration: visibility[line.label] ? 'none' : 'line-through',
                                textDecorationColor: visibility[line.label] ? 'none' : config.legend.fontColor,
                                textDecorationThickness: visibility[line.label] ? 'none' : '3px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                // Toggle visibility state for this line
                                setVisibility((prev) => ({ ...prev, [line.label]: !prev[line.label] }));
                            }}
                        >
                            {line.label}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className='d-flex flex-column'>
            <svg ref={svgRef} style={{ flex: 1 }}></svg>
            {renderLegend()}
        </div>
    );
};

export default LineChart;
