import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../states/AuthProvider';
import { useNavigate } from 'react-router-dom';

// Spinner
import Spinner from 'react-bootstrap/Spinner';

// Shield Logo
import shield from '../../assets/images/logo_shield.svg';

const Login = () => {
    const { login, isAuthenticated, loading, error } = useContext(AuthContext);
    const navigate = useNavigate();

    // Check if the user is authenticated
    useEffect(() => {
        if (isAuthenticated) {
            // If the user is logged in, redirect them to the dashboard
            navigate('/projects');
        }
    }, [isAuthenticated, navigate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        // get username and password from from
        const username = e.target.elements.username.value;
        const password = e.target.elements.password.value;
        login(username, password);
    };

    return (
        <div className="d-flex align-items-center justify-content-center h-100">
            <div
                className="card d-flex shadow-sm p-4"
                style={{ width: '100%', minHeight: "350px", maxWidth: '400px' }}>
                <img
                    src={shield}
                    style={{ width: '25px', height: '25px' }}
                    alt="shield"
                    className="align-self-center" />
                <h2 className="text-center m-0 fw-light">Welcome Back</h2>
                <p className='mb-4 align-self-center text-bold text-secondary'>
                    Log into your account below
                </p>
                {error && <div className="alert alert-danger">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label
                            htmlFor="username"
                            className="form-label">
                            Username
                        </label>
                        <input
                            disabled={loading}
                            type="text"
                            id="username"
                            className={`form-control ${loading && 'text-secondary'}`}
                            required />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="password"
                            className="form-label">
                            Password
                        </label>
                        <input
                            disabled={loading}
                            type="password"
                            id="password"
                            className={`form-control ${loading && 'text-secondary'}`}
                            required />
                    </div>
                    <button
                        disabled={loading}
                        className="btn btn-primary text-white w-100"
                        type="submit">
                        {loading ?
                            <>
                                <Spinner
                                    className='me-2'
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true" />
                                {"Authenticating your credentials..."}
                            </>
                            :
                            'Log In'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
