import React from "react";

// Icons
import { HiEllipsisHorizontal } from "react-icons/hi2";

// CSS
import './ProjectCard.css';

const ProjectCard = ({ project, callBack }) => {

    return (
        <div className="rounded shadow-sm bg-white px-3 py-4 projects-card" onClick={callBack}>
            <div className="d-flex flex-row w-100 justify-content-between">
                <div
                    className="tag"
                    style={{ backgroundColor: project.tag.color }} />
                <HiEllipsisHorizontal size={25} color="grey" />
            </div>
            <div className="card-body text-center">
                <h4 className="fw-bolder text-muted">{project.name}</h4>
                <p className="text-muted text-center m-0 project-description">{project.description}</p>
            </div>
            <div className="d-flex flex-row justify-content-between mt-3">
                <p className="m-0 text-muted project-owner">Owner: {project.project_manager.name}</p>
                <p className="m-0 text-muted">Updated at: {project.updated_at}</p>
            </div>
        </div>
    )
}

export default ProjectCard;