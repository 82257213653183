import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
    name: 'global',
    initialState: {
        searchQuery: null,
        loading: false,
        error: null
    },
    reducers: {
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
    },
    extraReducers: (builder) => {

    }
});

export const { setSearchQuery } = globalSlice.actions;
export default globalSlice.reducer;