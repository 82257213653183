import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Survey Questions
import surveyQuestions from '../../assets/data/questions.json';

// Sample Company Data
import sample_company_data from '../../assets/data/sample_data.json';

const dataSlice = createSlice({
    name: 'data',
    initialState: {
        companyData: sample_company_data,
        surveyQuestions: surveyQuestions,
        loading: null,
        error: null,
    },
    reducers: {
        addData(state, action) {
            state.companyData = action.payload;
        },
    },
});

export const { addData } = dataSlice.actions;
export default dataSlice.reducer;