// src/components/TopBar.js
import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Container, Nav, Form, FormControl, Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import SettingsWindow from '../settingsWindow/SettingsWindow';

// Redux
import { useDispatch } from 'react-redux';
import { setSearchQuery as setQuery } from '../../../states/globalSlice';

// CSS
import './TopBar.css';

// Assests
import logo from '../../../assets/images/logo.svg';

// auth
import { AuthContext } from '../../../states/AuthProvider';


const TopBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const { isAuthenticated, logout } = useContext(AuthContext);

  const handleLogout = () => logout();

  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(setQuery(searchQuery));
  };

  useEffect(() => {
    if (window.location.hash === '#settings') {
      setIsSettingsOpen(true);
    }
  }, []);

  const openSettings = () => {
    window.location.hash = 'settings';
    setIsSettingsOpen(true);
  };

  const closeSettings = () => {
    window.history.pushState('', document.title, window.location.pathname);
    setIsSettingsOpen(false);
  };

  return (
    <>
      <Navbar
        expand="lg"
        expanded={navbarOpen}
        onToggle={() => setNavbarOpen(!navbarOpen)}
        className="bg-white p-0 top-bar-nav shadow-none border-bottom"
      >
        <div className="container-fluid align-items-center justify-content-between">
          <Navbar.Brand className="ms-2" style={{ cursor: 'pointer' }}>
            <Link to="/">
              <img
                src={logo}
                width="auto"
                height="auto"
                className="d-inline-block align-top"
                alt=""
              />
            </Link>
          </Navbar.Brand>
          {isAuthenticated && (
            <>
              {/* Toggler */}
              <Navbar.Toggle
                className="top-bar-toggle"
                aria-controls="basic-top-bar-nav"
              >
                <FontAwesomeIcon className="top-bar-toggler-icon" icon={faBars} />
              </Navbar.Toggle>

              <Navbar.Collapse id="basic-top-bar-nav">
                <Nav className="align-items-center w-100">
                  {/* Search Bar */}
                  <Form
                    onSubmit={handleSearch}
                    className="d-flex top-bar-search-form mx-auto"
                  >
                    <FormControl
                      type="search"
                      placeholder="Search"
                      className="me-2 top-bar-search-input"
                      aria-label="Search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Form>
                  <Link className="nav-link top-bar-link" to="/projects">
                    Projects Navigator
                  </Link>
                  <Link className="nav-link top-bar-link" to="/profile">
                    Profile
                  </Link>
                  <a className="nav-link top-bar-link" onClick={openSettings}>
                    Settings
                  </a>
                </Nav>
              </Navbar.Collapse>
            </>
          )}
        </div>
      </Navbar>
      {isSettingsOpen && <SettingsWindow onClose={closeSettings} />}
    </>
  );
};

export default TopBar;