export default [
    {
        field: 'message', headerName: 'Message', width: 300,
        renderCell: (params) => (
            <strong>{params.value}</strong>
        )
    },
    { field: 'platform', headerName: 'Platform', width: 150 },
    {
        field: 'interactions', headerName: 'Interactions', width: 150,
        renderCell: (params) => (
            <strong style={{ color: "var(--bs-primary)" }}>{params.value}</strong>
        )
    },
    { field: 'origin', headerName: 'Origin', width: 200 },
    {
        field: 'source', headerName: 'Source', width: 200,
        renderCell: (params) => (
            <a href="" onClick={(e) => e.preventDefault()}>{params.value}</a>
        )
    },
    { field: 'sentiment', headerName: 'Sentiment', width: 150 },

];