import React from "react";

const HollowContainer = ({ title, sub_header, col, children }) => {

    const content = (
        <div className="border border-3 border-white rounded shadow-sm px-3 py-4 h-100">
            <div className='mt-2'>
                <h2 className='text-subtext fw-light m-0'>{title}</h2>
                <h2 className='text-primary fw-medium'>{sub_header}</h2>
            </div>
            <div >
                {children}
            </div>
        </div>
    );

    // If a bootstrap grid column is provided, wrap the content in a div with the column class
    return (
        col ?
            <div className={col}>{content}</div>
            :
            content
    );
}

export default HollowContainer;