import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { borderRadius, styled } from '@mui/system';

const Table = ({ rows, columns }) => {

    return (
        <div style={{ minHeight: "200px", maxHeight: "500px", width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                sx={{
                    '&, [class=MuiDataGrid]': { borderRadius: '15px' },
                    // Change color of Checkbox in Column Header
                    ".MuiDataGrid-columnHeaderCheckbox": {
                        backgroundColor: "var(--bs-primary)",
                        ".MuiSvgIcon-root": {
                            color: "white",
                        },
                        ".MuiDataGrid-iconSeparator": {
                            opacity: "0%",
                        },
                    },
                    // Change color of Checkbox in Rows
                    ".MuiDataGrid-cellCheckbox": {
                        backgroundColor: "var(--bs-primary)",
                        ".MuiSvgIcon-root": {
                            color: "white",
                        },
                    },
                    ".MuiDataGrid-iconButtonContainer": {
                        ".MuiSvgIcon-root": {
                            color: "var(--bs-primary)",
                        },
                    },
                    ".MuiDataGrid-menuIcon": {
                        ".MuiSvgIcon-root": {
                            color: "var(--bs-primary)",
                        },
                    },
                }}
            />
        </div>
    );
};

export default Table;