import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { selectProject, getProjects } from '../projectsSlice';

// Components
import PageHeader from '../../../components/containers/page-header/PageHeader';
import ProjectCard from '../components/ProjectCard';

const ProjectsNavPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Project states
    const { byId, allIds } = useSelector(state => state.projects);


    // Fetch all projects
    useEffect(() => {
        dispatch(getProjects());
    }, []);

    // Handle project selection
    const handleProjectSelect = (id) => {
        dispatch(selectProject(id));
        navigate(`/projects/${id}/`);
    };

    return (
        <div className='container'>
            <PageHeader title='Projects' sub_header='All of your active projects.' />
            <div className='row gx-4 gy-2'>
                {allIds.map(id => (
                    <div
                        className="col-md-4"
                        key={`projects-nav-page-${id}`}>
                        <ProjectCard
                            project={byId[id]}
                            callBack={() => handleProjectSelect(id)} />
                    </div>
                ))}
            </div>
        </div>
    )
};

export default ProjectsNavPage;
