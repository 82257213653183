import ExposureLevel from "../components/ExposureLevel";

export const narrativeColumns = (selectedProjectId, navigate) => [
    {
        field: 'name', headerName: 'Name', width: 300,
        renderCell: (params) => (
            <div
                className="narrative-table-name"
                onClick={() => navigate(`/projects/${selectedProjectId}/narratives/${params.row.id}`)}
            >
                {params.value}
            </div>
        )
    },
    {
        field: 'exposureLevel', headerName: 'Exposure Level', width: 200,
        renderCell: (params) => (
            <ExposureLevel type={params.value} />
        )
    },
    { field: 'mainPlatforms', headerName: 'Main Platforms', width: 200 },
    { field: 'origin', headerName: 'Origin', width: 200 },
    {
        field: 'tag', headerName: 'Tag', width: 200,
        renderCell: (params) => (
            // Add a colored space depending on the tag
            <div
                className='d-flex justify-content-start align-items-center'
                style={{
                    height: "100%", width: "100%"
                }} >
                <div
                    style={{ backgroundColor: `${params.value}`, height: "40px", width: "40px", borderRadius: "10px" }}
                />
            </div>
        )
    },
];