// src/routes.js
import React from 'react';
import { HiOutlineTable, HiOutlinePencil, HiOutlinePresentationChartBar } from 'react-icons/hi';
import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2';
import ai_icon from '../assets/images/ai_icon.svg';

export const routes = {
    analytics: (id) => `/projects/${id}`,
};

export const getMenuItems = (
    selectedProject,
    companyData,
    surveyQuestions,
    allIds,
    byIdNarratives
) => [
        {
            name: `${selectedProject.name}`,
            path: `/projects/${selectedProject.id}`,
            showInSidebar: false,
        },
        {
            name: 'Data',
            icon: <HiOutlineTable className="me-1" />,
            path: `/projects/${selectedProject.id}/data`,
            isCollapsible: true,
            children: [
                {
                    name: 'Manual Collection',
                    icon: <HiOutlinePencil className="me-1" />,
                    path: `/projects/${selectedProject.id}/data/manual-collection`,
                    isCollapsible: true,
                    children: companyData.map((topic, index) => ({
                        name: surveyQuestions.find((q) => q.id === topic.id).category,
                        path: `/projects/${selectedProject.id}/data/manual-collection/${topic.id}`,
                        isManualDataLink: true,
                        statusLabel: topic.statusLabel,
                        status: topic.status,
                        index: index + 1,
                        last: index === companyData.length - 1,
                    })),
                },
                {
                    name: 'Automated Collection',
                    icon: (
                        <img
                            src={ai_icon}
                            alt="AI"
                            className="me-1"
                            style={{ height: '20px' }}
                        />
                    ),
                    path: `/projects/${selectedProject.id}/data/automated-collection`,
                },
            ],
        },
        {
            name: 'Narratives',
            icon: <HiOutlineChatBubbleLeftRight className="me-1" />,
            path: `/projects/${selectedProject.id}/narratives`,
            isCollapsible: true,
            children: allIds.map((id) => ({
                name: byIdNarratives[id].name,
                path: `/projects/${selectedProject.id}/narratives/${id}`,
            })),
        },
        {
            name: 'Insights Report',
            icon: <HiOutlinePresentationChartBar className="me-1" />,
            path: `/projects/${selectedProject.id}/risk-report`,
        },
    ];
