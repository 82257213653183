import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';

import { HiMiniBuildingOffice, HiArrowPathRoundedSquare } from "react-icons/hi2";
// Spinner
import Spinner from 'react-bootstrap/Spinner';
// Components
import PageHeader from '../../../components/containers/page-header/PageHeader';
import ContentContainer from '../../../components/containers/content-container/ContentContainer';

// Graphy
import Map from '../../../components/graphs/map/Map';
import MapLegend from '../../../components/graphs/map/utils/MapLegend';
import MapLayer from '../../../components/graphs/map/utils/MapLayer';

const countryLayers = [{
    id: 1,
    name: 'Belgium',
},
{
    id: 2,
    name: 'Netherlands',
},
{
    id: 3,
    name: 'Switzerland',
},
{
    id: 4,
    name: 'Poland',
},
{
    id: 5,
    name: 'Czech Republic',
},
{
    id: 6,
    name: 'Slovakia',
},
{
    id: 7,
    name: 'Hungary',
},
{
    id: 9,
    name: 'Croatia',
},
{
    id: 10,
    name: 'Germany',
}]

const WebscrapersPage = () => {
    const [companyReport, setCompanyReport] = useState(null);
    const [loadingReport, setLoadingReport] = useState(false);
    const [scrapingStatus, setScrapingStatus] = useState(null);

    const handleSubmit = async () => {
        setLoadingReport(true);
        setScrapingStatus(<>
            <Spinner
                className='me-2 text-secondary'
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true" />
            Intitiating webscrapers...
        </>);
        // get company name and domain from form
        const companyName = "Naima_Media_GmbH";
        const domain = "https://www.naima-media.de";
        // call API to generate report
        const response = await axios.post(process.env.REACT_APP_API_URL + '/scrape-company/', {
            company_name: companyName,
            company_url: domain
        }, {
            withCredentials: true,  // Ensure cookies are sent
            withXSRFToken: true,
            xsrfHeaderName: 'X-CSRFTOKEN',
            xsrfCookieName: 'csrftoken',
        });
        setCompanyReport(response.data);
        setTimeout(() => {
            setScrapingStatus(<>
                <Spinner
                    className='me-2 text-secondary'
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true" />
                Collecting page data...
            </>);
            setTimeout(() => {
                setScrapingStatus(<>
                    <Spinner
                        className='me-2 text-secondary'
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" />
                    Analyzing data...
                </>);
                setTimeout(() => {
                    setScrapingStatus(null);
                    setLoadingReport(false);
                }, 2000);
            }, 3000);
        }, 2000);
    }

    const handleNameChange = (e) => {
        e.preventDefault();
    }

    const handleDomainChange = (e) => {
        e.preventDefault();
    }

    return (
        <div className="container">
            <PageHeader
                title="Webscrapers" />
            <div className="row g-2">
                <div className="col-lg-12 col-xl-6">
                    <ContentContainer title='Configurations'>
                        <p className='text-muted'>
                            These are the current configurations for the webscrapers. You can update the configurations by changing the company name and domain.
                        </p>
                        <Form
                            className="d-flex flex-column position-relative gap-2"
                            onSubmit={(e) => e.preventDefault()}>
                            <label htmlFor="companyName">Company Name</label>
                            <Form.Control
                                id='companyName'
                                type="text"
                                placeholder="Company Name"
                                value={"TechSecure Ltd."}
                                className="me-2"
                                onChange={handleNameChange}
                                disabled
                                required
                            />
                            <div className='d-flex justify-content-between align-items-end mt-3'>
                                <label htmlFor="domain">Company Domain</label>
                                <button className="btn btn-outline-primary py-1">+ Add Domain</button>
                            </div>
                            <Form.Control
                                id='domain'
                                type="text"
                                placeholder="Company Domain"
                                value={"https://www.techsecure.com"}
                                className="me-2"
                                onChange={handleDomainChange}
                                disabled
                                required
                            />
                        </Form>
                    </ContentContainer>
                </div>
                <div className="col-lg-12 col-xl-6">
                    <ContentContainer title='Run webscrapers manually'>
                        <p className='text-muted'>
                            You can run the webscrapers manually by clicking the button below. This will generate a report with the data that was collected. The report will be displayed below.
                            Any new data will be added to the existing data.
                        </p>
                        <button
                            className="btn btn-primary text-white mt-auto"
                            onClick={handleSubmit}>
                            {scrapingStatus && loadingReport ? scrapingStatus : 'Run Webscrapers'}
                        </button>
                    </ContentContainer>
                </div>
                {companyReport && !loadingReport &&
                    <>
                        <div className="col-12 ">
                            <ContentContainer title='Data Collected:'>
                                <p className='text-muted'>
                                    The webscrapers have collected the following data from the specified webpages. Some of this data may not have changed since the last time the webscrapers were run.
                                </p>
                                <span className='text-secondary fst-italic'><HiArrowPathRoundedSquare /> All changes were saved to the database.</span>
                            </ContentContainer>
                        </div>
                        <div className="col-12">
                            <ContentContainer title={
                                <div className='d-flex align-items-center'>
                                    <HiMiniBuildingOffice />
                                    TechSecure Ltd. Report
                                </div>
                            }>
                                <div className='d-flex justify-content-center row gap-0 row-gap-3 column-gap-3 mt-5'>
                                    {Object.entries(companyReport.report).map(([key, value]) => {
                                        // exclude id, company, model_used
                                        if (key === 'id' || key === 'company' || key === 'model_used') {
                                            return null;
                                        }

                                        // if the value is an array, we turn the value part into a list of items
                                        if (value === null) {
                                            value = <p className='fst-italic text-secondary m-0'>No data was found</p>;
                                        } else if (key === 'locations_and_offices') {
                                            const legendContent = [
                                                { name: 'Locations', color: 'blue' },
                                            ];

                                            value = <Map
                                                legends={[
                                                    { name: "Locations", component: <MapLegend title='Locations' content={legendContent} /> },
                                                ]}
                                                countryLayers={[
                                                    { name: "Locations", layers: <MapLayer label={'locations'} layers={countryLayers} withPopUp={false} /> }
                                                ]}
                                                height={"400px"}
                                                includeFilters={false}
                                            />
                                        } else if (Array.isArray(value)) {
                                            value = value.map((item, index) => {
                                                return <li
                                                    key={index}
                                                    className='mb-3'>{item}</li>
                                            });
                                        } else {
                                            value = value.toString();
                                        }

                                        // replace '_' with ' ' and capitalize first letters of each word
                                        key = key.replace(/_/g, ' ');
                                        key = key.replace(/\b\w/g, l => l.toUpperCase());

                                        return (
                                            <div
                                                key={key}
                                                className='d-flex column col-12 text-center p-3 border-bottom'
                                            >
                                                <div className='col-4 text-start'>
                                                    <h4 className='text-primary'>{key}</h4>
                                                </div>
                                                <div className='col-8 text-start'>
                                                    {value}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </ContentContainer>
                        </div>
                    </>
                }
            </div>
        </div>
    )
};

export default WebscrapersPage;