// src/components/SideBar.js
import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaBars, FaTimes } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import { getMenuItems, routes } from '../../../app/routes';
import { AuthContext } from '../../../states/AuthProvider';

// Icons
import { HiChevronDoubleRight } from "react-icons/hi2";

// Import the ManualDataLink component
import ManualDataLink from './links/ManualDataLink';

const SideBar = () => {
    const { isAuthenticated } = useContext(AuthContext);

    const { byId, selectedProjectId } = useSelector((state) => state.projects);
    const loadingProject = useSelector((state) => state.projects.loading);
    const { allIds } = useSelector((state) => state.narratives);
    const byIdNarratives = useSelector((state) => state.narratives.byId);
    const { companyData, surveyQuestions } = useSelector((state) => state.data);

    const [openSections, setOpenSections] = useState({});
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleToggleSection = (sectionName) => {
        setOpenSections((prev) => ({
            ...prev,
            [sectionName]: !prev[sectionName],
        }));
    };

    const toggleSidebar = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };

    if (!isAuthenticated || !selectedProjectId || loadingProject) {
        return (
            <div className="navbar-custom navbar-vertical bg-white border-end px-2">
                <nav></nav>
                <div className="agency-tag">
                    <p>
                        Demo created by<br />
                        <b>faltstein Solutions</b>
                    </p>
                </div>
            </div>
        );
    }

    const menuItems = getMenuItems(
        {
            id: selectedProjectId,
            name: byId[selectedProjectId] ? byId[selectedProjectId].name : null,
        },
        companyData,
        surveyQuestions,
        allIds,
        byIdNarratives
    );

    return (
        <>
            <button
                className={`sidebar-toggle-button rounded-circle  ${isSidebarOpen ? 'open' : ''}`}
                onClick={toggleSidebar}
            >
                <HiChevronDoubleRight className='sidebar-toggle-button-icon' style={{ zIndex: "10001" }} />
            </button>
            <div
                className={`navbar-custom navbar-vertical ${isSidebarOpen ? 'open' : ''} bg-white border-end px-2`}
                style={{ fontWeight: '300' }}
            >
                <nav>
                    <NavLink
                        to={routes.analytics(selectedProjectId)}
                        style={{ textDecoration: 'none' }}
                    >
                        <h5 className="text-secondary px-2 mt-2">
                            {byId[selectedProjectId].name}
                        </h5>
                    </NavLink>
                    <ul className="navbar-nav mr-auto gap-0 pt-2">
                        {menuItems.map((item, index) => {
                            // We hide some links that we dont show in the sidebar (eg Project)
                            if (item.showInSidebar !== undefined && !item.showInSidebar) {
                                return null;
                            }
                            return (
                                <MenuItem
                                    key={index}
                                    item={item}
                                    openSections={openSections}
                                    handleToggleSection={handleToggleSection}
                                    isParent={true}
                                />
                            )
                        })}
                    </ul>
                </nav>
                <div className="agency-tag">
                    <p>
                        Demo created by<br />
                        <b>faltstein Solutions</b>
                    </p>
                </div>
            </div>
        </>
    );
};

const MenuItem = ({ item, openSections, handleToggleSection, isParent = false }) => {
    const hasChildren = item.children && item.children.length > 0;
    const isOpen = openSections[item.name];
    const isActive = useLocation().pathname.includes(item.path);

    const handleClick = (e) => {
        if (item.isCollapsible) {
            e.preventDefault();
            handleToggleSection(item.name);
        }
    };

    if (item.isManualDataLink) {
        // Render using the ManualDataLink component
        return (
            <li className="nav-item m-0">
                <ManualDataLink
                    NavLinkInfo={{
                        category: item.name,
                        statusLabel: item.statusLabel,
                        status: item.status,
                        index: item.index,
                        to: item.path,
                    }}
                    last={item.last}
                />
            </li>
        );
    }

    return (
        <li className={`d-flex flex-column justify-content-start nav-item m-0 mb-2 ${isActive && isParent && "active-nav-section"}`}>
            <NavLink
                to={item.path}
                className={({ isActive }) =>
                    `nav-link px-2 py-1 ${isActive ? 'text-primary fw-normal' : ''}`
                }
                end
            >
                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <div className='d-flex align-items-center'>
                            {item.icon}
                        </div>
                        <div className="m-0">
                            {item.name}
                        </div>
                    </div>
                    <div className={`d-flex align-items-center ${item.isCollapsible && 'collapse-chevron'}`}
                        onClick={handleClick}>
                        {hasChildren && item.isCollapsible && (
                            isOpen ? (
                                <FaChevronUp
                                    className="mt-0 mx-auto"
                                    size="12px" />
                            ) : (
                                <FaChevronDown
                                    className="mt-0 mx-auto"
                                    size="12px" />
                            )
                        )}
                    </div>
                </div>
            </NavLink>
            {hasChildren && item.isCollapsible && (
                <Collapse in={isOpen}>
                    <div className="ps-3">
                        <ul className="navbar-nav">
                            {item.children.map((childItem, idx) => (
                                <MenuItem
                                    key={idx}
                                    item={childItem}
                                    openSections={openSections}
                                    handleToggleSection={handleToggleSection}
                                />
                            ))}
                        </ul>
                    </div>
                </Collapse>
            )}
            {hasChildren && !item.isCollapsible && (
                <ul className="navbar-nav ps-3">
                    {item.children.map((childItem, idx) => (
                        <MenuItem
                            key={idx}
                            item={childItem}
                            openSections={openSections}
                            handleToggleSection={handleToggleSection}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};

export default SideBar;
