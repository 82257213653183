import React from "react";

const ContentContainer = ({ title, sub_header, col, children, id }) => {

    const content = (
        <div
            id={id}
            // The styling is needed for the scroll behavior when the user scrolls to this element
            style={{ scrollMarginTop: '50px', transition: 'all 0.3s ease' }}
            className={`d-flex flex-column rounded shadow-sm bg-white px-3 py-4 h-100 overflow-hidden`}>
            {(title || sub_header) &&
                <div className='mt-2'>
                    <h2 className='text-subtext fw-light m-0'>{title}</h2>
                    <h2 className='text-primary fw-medium'>{sub_header}</h2>
                </div>
            }
            {children}
        </div>
    )

    // If a bootstrap grid column is provided, wrap the content in a div with the column class
    return (
        col ?
            <div className={col}>{content}</div>
            :
            content
    );
}

export default ContentContainer;